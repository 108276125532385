import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { popper } from '@popperjs/core';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './scss/custom.scss';
import './App.css';
import './css/nav.css';

import AppProtectedRoute from './component/AppProtectedRoute';
import PageHome from './component/page/PageHome'
import PageLogin from './component/page/PageLogin';
import PageRegister from './component/page/PageRegister';
import PageForgetPassword from './component/page/PageForgetPassword';
import PageResetPassword from './component/page/PageResetPassword';
import PageProfile from './component/page/PageProfile';
import PageFaq from './component/page/PageFaq';
import PageAllNumber from './component/page/PageAllNumber';
import PageContact from './component/page/PageContact';
import PageVerifyAccount from './component/page/PageVerifyAccount';
import PagePrivacyPolicy from './component/page/PagePrivacyPolicy';
import PageHowToRegister from './component/page/PageHowToRegister';
import PageHowAdsRecommended from './component/page/PageHowToAdsRecommended';
import PageHowToAddPhoneNumberExcel from './component/page/PageHowToAddPhoneNumberExcel';
import { useAuthContext } from './context/AuthContext';



function App() {
  const authContext = useAuthContext();

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<PageHome />} />
          <Route path="/login" element={<PageLogin />} />
          <Route path="/register" element={<PageRegister />} />
          <Route path="/all-number/:page" element={<PageAllNumber />} />
          <Route path="/all-number" element={<PageAllNumber />} />
          <Route path="/forget-password" element={<PageForgetPassword />} />
          <Route path="/reset-password/:code" element={<PageResetPassword />} />
          <Route path="/verify-account/:code" element={<PageVerifyAccount />} />
          <Route path="/faq" element={<PageFaq />} />
          <Route path="/contact" element={<PageContact />} />
          <Route path="/privacy-policy" element={<PagePrivacyPolicy />} />
          <Route path="/how-to-register" element={<PageHowToRegister />} />
          <Route element={<AppProtectedRoute />} >
          <Route path="/how-to-ads-recommneded" element={<PageHowAdsRecommended />} />
            <Route path="/how-to-add-phone-number/excel" element={<PageHowToAddPhoneNumberExcel />} />
            <Route path="/account/:page/:nestedPage/:nestedPage2" element={<PageProfile />} />
            <Route path="/account/:page/:nestedPage" element={<PageProfile />} />
            <Route path="/account/:page" element={<PageProfile />} />
            <Route path="/account" element={<PageProfile />} />
          </Route>
        </Routes>
      </BrowserRouter>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover
      />
    </>
  );
}

export default App;
