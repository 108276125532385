
import React, { useEffect, useRef } from 'react';
import { useLayoutContext } from '../../context/LayoutContext';
import MainHeader from '../nav/MainHeader';
import MainFooter from '../nav/MainFooter';
import { useLocation } from 'react-router-dom';

export default function PageWrapper(props) {
    const layoutcontext = useLayoutContext();
    const pageWrapperRef = useRef();
    const location = useLocation()

    useEffect(() => {
        layoutcontext.toggleOffSidebar();
    }, [location]);

    return (
        <div id="app" ref={pageWrapperRef}>
            <div id="main" className="d-flex flex-column">
                <MainHeader />
                <main className="py-3">
                    {props.children}
                </main>
                <MainFooter />
            </div>
        </div>
    )
}
