import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import withReactContent from 'sweetalert2-react-content';
import i18next from 'i18next';

const MySwal = withReactContent(Swal);

const success = (message, callback) => {
    MySwal.fire({
    icon: "success",
    html: <>{message}</>,
    customClass: {
      confirmButton: "btn btn-success",
    },
    buttonsStyling: false,
    confirmButtonText: i18next.t('ok'),
  }).then(() => {
    if (typeof callback === "function") {
      callback();
    }
  });
};

const error = (message, callback) => {
    MySwal.fire({
    icon: "error",
    html: <>{message}</>,
    customClass: {
      confirmButton: "btn btn-danger",
    },
    buttonsStyling: false,
    confirmButtonText: i18next.t('ok'),
  }).then(() => {
    if (typeof callback === "function") {
      callback();
    }
  });
};

const toastSuccess = (message) => {
  toast.success(message, {
    theme: "colored",
    bodyClassName: "m-toast",
  });
};

const toastError = (message) => {
  toast.error(message, {
    theme: "colored",
    bodyClassName: "m-toast",
  });
};

const tryGetErrorMessage = (obj, init) => {
  if(typeof obj !== "undefined"){
    if(typeof obj.response !== "undefined"){
      if(typeof obj.response.data !== "undefined") {
        if(typeof obj.response.data.message !== "undefined"){
          return obj.response.data.message;
        }
      }
    } else if(typeof obj.data !== "undefined") {
      if(typeof obj.data.message !== "undefined"){
        return obj.data.message;
      }
    } else if(typeof obj.message !== "undefined"){
      return obj.message;
    }
  }
 
  if(init === "form"){
    return i18next.t("error_please_check_data")
  }
  else if(init === "xhr"){
    return i18next.t("error_xhr")
  }
  return i18next.t("error");
}

const tryGetSuccessMessage = (obj, init) => {
  if(typeof obj !== "undefined"){
    if(typeof obj.data !== "undefined") {
      if(typeof obj.data.message !== "undefined"){
        return obj.data.message;
      }
    } else if(typeof obj.message !== "undefined"){
      return obj.message;
    }
  }
 
  return i18next.t("success");
}

export default {
  tryGetErrorMessage,
  tryGetSuccessMessage,
  success,
  error,
  toastSuccess,
  toastError,
};
