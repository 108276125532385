import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaBars } from 'react-icons/fa';
import { useParams, NavLink } from 'react-router-dom';
import MyStorePhoneNumberRoot from './MyStorePhoneNumberRoot';
import MyStorePhoneNumberAdd from './MyStorePhoneNumberAdd';
import MyStorePhoneNumberAddExcel from './MyStorePhoneNumberAddExcel';

export default function MyStorePhoneNumber() {
    const { t } = useTranslation();
    const { nestedPage2 } = useParams();

    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-light bg-white">
                <div className="container-fluid px-0">
                    <h3 className="fw-bold my-3 mr-lg-3">{t('phone_number_for_sale')}</h3>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#acc-pn-dropdown" aria-controls="acc-pn-dropdown" aria-expanded="false" aria-label="Toggle navigation">
                        <FaBars />
                    </button>
                    <div className="collapse navbar-collapse flex-grow-0" id="acc-pn-dropdown">
                        <ul className="navbar-nav" role="group">
                            <li className="nav-item">
                                <NavLink to={{ pathname: `/account/my-store/phone-number` }} className={`nav-link btn btn-primary mb-3 mb-lg-0 me-lg-2`} end>{t('search')}</NavLink>
                            </li>
                            <li className="nav-item dropdown">
                                <a className={`nav-link dropdown-toggle btn btn-primary mb-3 mb-lg-0`} href="#" id="acc-pn-add-menu-link" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    {t('add')}
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="acc-pn-add-menu-link">
                                    <NavLink to={{ pathname: `/account/my-store/phone-number/add` }} className={`dropdown-item`}>{t('add_phone_number_manual')}</NavLink>
                                    <NavLink to={{ pathname: `/account/my-store/phone-number/add-excel` }} className={`dropdown-item`}>{t('add_phone_number_excel')}</NavLink>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <hr className="mt-0" />
            {
                {
                    "add": <MyStorePhoneNumberAdd />,
                    "add-excel": <MyStorePhoneNumberAddExcel />,
                    undefined: <MyStorePhoneNumberRoot />
                }[nestedPage2] 
            }
        </>
    )
}
