import React from 'react';

export default function MainNav(props) {

    return (
        <div className="page-top my-3">
            <div className="d-flex justify-content-between align-items-center">
                {props.pageTitle && <><h5 className="m-0 title fw-bold">{props.pageTitleIcon && <>{props.pageTitleIcon}&nbsp;</> } {props.pageTitle}</h5></> }
                {props.children && <>{props.children}</>}
            </div>
            <hr />
        </div>
    )
}
