import React, { useEffect, useState } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import FormRegister from '../form/FormRegister';
import { useAuthContext } from '../../context/AuthContext';
import { console_success } from '../../js/dev';
import SecondFooter from '../nav/SecondFooter';
import { MdStorefront } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import img_logo from './../../logo.png';
import './../../css/page-focus-center.css';

export default function PageRegister() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const authContext = useAuthContext();
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        if (authContext.activeUser !== null && isLoggedIn) {
            return navigate("/");
        }
    }, [authContext.activeUser, isLoggedIn]);

    const onSuccess = (result) => {
        console_success("User register successfully");
        setIsLoggedIn(true);
    }

    const onError = (error) => {
    }

    return (
        <>
            <div id="app" className="page-focus-center">
                {
                    authContext.activeUser === null ? 
                    <div className="container-lg my-auto">
                    <div className="row justify-content-center my-5">
                        <div className="col-md-8 col-lg-6">
                            <div className="page-form-logo row justify-content-center">
                                <div className="col-8 col-sm-6 col-lg-4">
                                    <NavLink to={{ pathname: '/' }}>
                                        <img src={img_logo} alt={process.env.REACT_APP_NAME} className="w-100 mb-4" />
                                    </NavLink>
                                </div>
                            </div>
                            <div className="rounded bg-white shadow px-4 py-5">
                                <h3><MdStorefront /> {t('register_sales_person')}</h3>
                                <hr />
                                <FormRegister onSuccess={onSuccess} onError={onError} showErrorInfo={true} />
                                <div className="d-flex justify-content-between mt-3">
                                    <NavLink to={{ pathname: '/forget-password' }} className="text-decoration-none">{t('forget_password')}?</NavLink>
                                    <NavLink to={{ pathname: '/login' }} className="text-decoration-none">{t('login')}</NavLink>
                                </div>
                                <br />
                                <NavLink className={`text-decoration-none`} to={{ pathname: '/how-to-register' }}>{t('how_to_register_as_sales_person')}</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
                    :
                    <div className="my-auto p-5 text-center"><h5 className="text-danger">{t('cant_register_cus_now_logged_in')}</h5></div>
                }
                

                <SecondFooter />
            </div>

        </>
    )
}
