import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPinField from 'react-pin-field';
import './../css/form.css';
import { usePrefixContext } from '../context/PrefixContext';
import { AiTwotoneLike, AiTwotoneDislike } from 'react-icons/ai';
import PsButton from './element/PsButton';
import { BsSearch } from 'react-icons/bs';
import { IoRefresh } from 'react-icons/io5';
import { MdOutlineLibraryAdd, MdWifiCalling3 } from 'react-icons/md';
import { FaRegMoneyBillAlt, FaSignal } from 'react-icons/fa';
import api from '../js/api';

const PhoneNumberSearchForm = forwardRef((props, ref) => {
    const { t } = useTranslation();
    // const [phoneNumberSearchResult, setPhoneNumberSearchResult] = useState([]);
    const [isFetching, setIsFetching] = useState(false);

    useImperativeHandle(ref, () => ({
        isFetching: isFetching,
    }), [isFetching]);

    const numberPin1Ref = useRef();
    const numberPin2Ref = useRef();

    const defaultLikeNumber = [
        { number: 0, status: "" },
        { number: 1, status: "" },
        { number: 2, status: "" },
        { number: 3, status: "" },
        { number: 4, status: "" },
        { number: 5, status: "" },
        { number: 6, status: "" },
        { number: 7, status: "" },
        { number: 8, status: "" },
        { number: 9, status: "" },
    ];
    const defaultPriceRange = [
        { text: `${t('all')}`, min: 0, max: 0, },
        { text: `${t('less_than')} - 200,000`, min: 0, max: 200000, },
        { text: `200,001 - 500,000`, min: 200001, max: 500000, },
        { text: `500,001 - 1,000,000`, min: 500001, max: 1000000, },
        { text: `1,000,001 - 3,000,000`, min: 1000001, max: 3000000, },
        { text: `${t('greater_than')} 3,000,000`, min: 3000000, max: 0, },
    ];

    const [sum, setSum] = useState(0);
    const [packageType, setPackageType] = useState(0);
    const [provider, setProvider] = useState(0);
    const [numberFormatType, setNumberFormatType] = useState(8);
    const [number, setNumber] = useState("");
    const { prefix, isCompleted } = usePrefixContext();
    const [isSelectLikeNumber, setIsSelectLikeNumber] = useState(true);
    const [likeNumber, setLikeNumber] = useState(defaultLikeNumber);
    const [priceRange, setPriceRange] = useState("0-0");

    useEffect(() => {
        if (numberPin1Ref.current) {
            onInputPinChanged(1);
        }
    }, [numberPin1Ref]);

    useEffect(() => {
        setNumber(numberFormatType === 7 ? "*******" : "********");
    }, [numberFormatType]);

    const pin3Props = {
        length: 3,
        className: "form-control text-center mb-2",
        validate: /^[0-9]$/,
        format: key => key,
    }
    const pin4Props = {
        length: 4,
        className: "form-control text-center mb-2",
        validate: /^[0-9]$/,
        format: key => key,
    }

    const onChangeNumberFormat = (e) => {
        setNumberFormatType(parseInt(e.target.value));
        setTimeout(() => {
            numberPin1Ref.current.inputs[0].focus();
        }, 100);
    }

    const onInputPinChanged = (id, code) => {
        let num = [];
        for (const element of numberPin1Ref.current.inputs) {
            num.push(element.value !== "" ? element.value : "*");
        }
        for (const element of numberPin2Ref.current.inputs) {
            num.push(element.value !== "" ? element.value : "*");
        }
        const str = num.join("");
        setNumber(str);

        // if(id === 2 && numberPin2Ref.current.inputs[0].value === "" && document.activeElement === numberPin2Ref.current.inputs[0]){
        //     numberPin1Ref.current.inputs[numberPin1Ref.current.inputs.length - 1].focus();
        // }
    }

    const onInputPinCompleted = (id) => {
        if (id === 1 && document.activeElement === numberPin1Ref.current.inputs[numberPin1Ref.current.inputs.length - 1]) {
            numberPin2Ref.current.inputs[0].focus();
        }
    }

    const onInputPinResolved = (id) => {
        console.log(id)
    }

    const onSelectLikeNumber = (e, i) => {
        const tmp = [...likeNumber];
        if (isSelectLikeNumber) {
            if (tmp[i].status === "like") {
                tmp[i].status = "";
            } else {
                tmp[i].status = "like";
            }
        } else {
            if (tmp[i].status === "dislike") {
                tmp[i].status = "";
            } else {
                tmp[i].status = "dislike";
            }
        }
        setLikeNumber([...tmp]);
    }

    const submitForm = async () => {
        const like = [];
        const dislike = [];
        for (const l of likeNumber) {
            if (l.status === "like")
                like.push(l.number);
            else if (l.status === "dislike")
                dislike.push(l.number);
        }

        const params = {
            number: number,
            format: numberFormatType,
            sum: sum,
            package: packageType,
            provider: provider,
            like: like.join(","),
            dislike: dislike.join(","),
            price: priceRange
        }
        // console.log(params)
        if (props.onBeforeSearch) {
            await props.onBeforeSearch();
        }

        try {
            setIsFetching(true);
            if (props.setIsFetching) {
                props.setIsFetching(true);
            }
            const fetch = await api.get(`phone-number`, { params: params });
            if (props.onSearchSuccess) {
                await props.onSearchSuccess(fetch.data);
            }
        } catch (error) {
            if (props.onSearchSuccess) {
                await props.onSearchSuccess([]);
            }
        } finally {
            setIsFetching(false);
            if (props.setIsFetching) {
                props.setIsFetching(false);
            }
        }
    }

    const resetForm = () => {
        for (const element of numberPin1Ref.current.inputs) {
            element.value = "";
        }
        for (const element of numberPin2Ref.current.inputs) {
            element.value = "";
        }
        setNumberFormatType(8);
        setSum(0);
        setPackageType(0);
        setProvider(0);
        setIsSelectLikeNumber(true);
        setLikeNumber(defaultLikeNumber);
        setPriceRange("0-0");
        setNumber("");
    }

    const onSubmitForm = (e) => {
        console.log("sub")
    }

    return (
        <>
            {isCompleted ?
                <form onSubmit={(e) => { onSubmitForm(e) }}>
                    <fieldset disabled={isFetching}>
                        <div className="bg-white rounded p-3 my-3 shadow-sm border search-form">
                            <div className="row">

                                <div className="col-lg-9 col-xl-9">

                                    <div className="row gx-2 mb-3">

                                        <div className="col-6 col-sm-3 col-md-3">
                                            <label className="form-label"><BsSearch /> {t('search_phone_number')}</label>
                                            <select name="number-format-type" id="number-format-type" className="form-select mb-2 w-100 me-2 bg-light" value={numberFormatType} onChange={(e) => onChangeNumberFormat(e)}>
                                                <option value={7}>{t('phone_number_format_type_7')}</option>
                                                <option value={8}>{t('phone_number_format_type_8')}</option>
                                            </select>
                                        </div>

                                        <div className="col-6 col-sm-3 col-md-3 order-md-last">
                                            <label className="form-label" htmlFor="phone-number-sum"><MdOutlineLibraryAdd /> {t('phone_number_sum')}</label>
                                            <select name="phone-number-sum" id="phone-number-sum" className="form-select mb-2 bg-light" value={sum} onChange={e => setSum(e.target.value)}>
                                                <option value={0}>{t('all')}</option>
                                                {prefix.phone_number_sum_range.map((e, i) => {
                                                    return <option key={`phone-number-sum-opt-${e}`}>{e}</option>
                                                })}
                                            </select>
                                        </div>

                                        <div className="col-12 col-md-6">
                                            <label className="form-label text-white d-none d-md-inline-block">{t('search_phone_number')}</label>
                                            <div className={`number-pin-container length-${numberFormatType}`}>
                                                {numberFormatType === 7
                                                    ? <>
                                                        <ReactPinField ref={numberPin1Ref} {...pin3Props} key="pin7-1" onComplete={() => { onInputPinCompleted(1) }} onChange={(code) => onInputPinChanged(1, code)} onResolveKey={() => onInputPinResolved(1)} />
                                                        <div className="sep">-</div>
                                                        <ReactPinField ref={numberPin2Ref} {...pin4Props} key="pin7-2" onComplete={() => { onInputPinCompleted(2) }} onChange={(code) => onInputPinChanged(2, code)} onResolveKey={() => onInputPinResolved(2)} />
                                                    </>
                                                    : <>
                                                        <ReactPinField ref={numberPin1Ref} {...pin4Props} key="pin8-1" onComplete={() => { onInputPinCompleted(1) }} onChange={(code) => onInputPinChanged(1, code)} onResolveKey={() => onInputPinResolved(1)} />
                                                        <div className="sep">-</div>
                                                        <ReactPinField ref={numberPin2Ref} {...pin4Props} key="pin8-2" onComplete={() => { onInputPinCompleted(2) }} onChange={(code) => onInputPinChanged(2, code)} onResolveKey={() => onInputPinResolved(2)} />
                                                    </>
                                                }
                                            </div>
                                        </div>

                                    </div>

                                    <div className="row gx-2 mb-3">
                                        <div className="col-12">
                                            <label className="form-label" htmlFor="number-like"><AiTwotoneLike /> {t('phone_number_like')}/{t('phone_number_dislike')}</label>
                                        </div>
                                        <div className="col-12 d-flex flex-wrap">
                                            <label htmlFor="check-is-like-true" className={`check-is-like`}>
                                                <input type="radio" name="check-is-like" checked={isSelectLikeNumber} id="check-is-like-true" value={true} onChange={(e) => { setIsSelectLikeNumber(true) }} />
                                                <span className="mark">
                                                    <span><AiTwotoneLike /> {t('phone_number_like')}</span>
                                                </span>
                                            </label>
                                            <label htmlFor="check-is-like-false" className={`check-is-like dislike`}>
                                                <input type="radio" name="check-is-like" checked={!isSelectLikeNumber} id="check-is-like-false" value={false} onChange={(e) => { setIsSelectLikeNumber(false) }} />
                                                <span className="mark">
                                                    <span><AiTwotoneDislike /> {t('phone_number_dislike')}</span>
                                                </span>
                                            </label>

                                            {likeNumber.map((e, i) => {
                                                return (
                                                    <label key={`phone-number-like-chkbx-${e.number}`} className={`phone-number-like-chkbx ${e.status}`} onClick={(e) => { onSelectLikeNumber(e, i) }}>
                                                        <span className="mark">{e.number}</span>
                                                    </label>
                                                )
                                            })}
                                        </div>

                                    </div>

                                    <div className="row gx-2 mb-3 d-none d-lg-flex">
                                        <div className="col-8">
                                            <PsButton icon={<BsSearch />} isLoading={isFetching} display="block" color="tertiary" handleClick={() => submitForm()}>{t('search_phone_number')}</PsButton>
                                        </div>
                                        <div className="col-4">
                                            <PsButton icon={<IoRefresh />} display="block" color="outline-tertiary" handleClick={() => resetForm()}>{t('clear_phone_number_search')}</PsButton>
                                        </div>
                                    </div>


                                </div>
                                <div className="col-lg-3 col-xl-3">
                                    <div className="row">
                                        <div className="col-4 col-lg-12 mb-3 mb-lg-0">
                                            <label className="form-label" htmlFor="sim-package-type"><MdWifiCalling3 /> {t('sim_package_type')}</label>
                                            <select name="sim-package-type" id="sim-package-type" className="form-select mb-2 bg-light" value={packageType} onChange={(e) => { setPackageType(parseInt(e.target.value)) }}>
                                                <option value={0}>{t('all')}</option>
                                                {Object.entries(prefix.sim_package_type).map(([key, value]) => {
                                                    return (<option value={value.id} key={`sim-package-type-opt-${value.id}`}>{value.name}</option>)
                                                })}
                                            </select>
                                        </div>
                                        <div className="col-4 col-lg-12 mb-3 mb-lg-0">
                                            <label className="form-label" htmlFor="sim-provider"><FaSignal /> {t('sim_provider')}</label>
                                            <select name="sim-provider" id="sim-provider" className="form-select mb-2 bg-light" value={provider} onChange={(e) => { setProvider(parseInt(e.target.value)) }}>
                                                <option value={0}>{t('all')}</option>
                                                {Object.entries(prefix.sim_provider).map(([key, value]) => {
                                                    return (<option value={value.id} key={`sim-provider-opt-${value.id}`}>{value.provider_name}</option>)
                                                })}
                                            </select>
                                        </div>
                                        <div className="col-4 col-lg-12 mb-3 mb-lg-0">
                                            <label className="form-label" htmlFor="price-range"><FaRegMoneyBillAlt /> {t('price_range')}</label>
                                            <select name="price-range" id="price-range" className="form-select mb-2 bg-light" value={priceRange} onChange={(e) => { setPriceRange(e.target.value) }}>
                                                {defaultPriceRange.map((e, i) => {
                                                    return (<option value={`${e.min}-${e.max}`} key={`price-range-opt-${i}`}>{e.text}</option>)
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row gx-2 mb-3 d-flex d-lg-none">
                                        <div className="col-8">
                                            <PsButton icon={<BsSearch />} type="submit" isLoading={isFetching} display="block" color="tertiary" handleClick={() => submitForm()}>{t('search_phone_number')}</PsButton>
                                        </div>
                                        <div className="col-4">
                                            <PsButton icon={<IoRefresh />} display="block" color="outline-tertiary" handleClick={() => resetForm()}>{t('clear_phone_number_search')}</PsButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
                : null}
        </>

    )
})

export default PhoneNumberSearchForm