import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PsButton from '../element/PsButton';
import { useAuthContext } from '../../context/AuthContext';
import { MdPending, MdOutlineCancel } from 'react-icons/md';
import { FaStar } from 'react-icons/fa';
import { AiFillEdit } from 'react-icons/ai';
import ProfileInfoEdit from './profile/ProfileInfoEdit';

export default function PageProfileInfo() {
    const { t } = useTranslation();
    const { activeUser, fetchUserData } = useAuthContext();
    const [isFormOpen, setIsFormOpen] = useState(false);

    const switchEditFormAndInfo = (state) => {
        setIsFormOpen(state);
    }
    
    function ProfileInfoTable() {
        return (
            <table className="table">
                <tbody>
                    <tr>
                        <td className="text-black-50" style={{ width: '25%' }}>{t('fullname')}</td>
                        <td><strong>{activeUser.info.fullname}</strong></td>
                    </tr>
                    <tr>
                        <td className="text-black-50">{t('email')}</td>
                        <td><strong>{activeUser.info.email}</strong></td>
                    </tr>
                    <tr>
                        <td className="text-black-50">{t('store_name')}</td>
                        <td><strong>{activeUser.info.store_name}</strong></td>
                    </tr>
                    <tr>
                        <td className="text-black-50">{t('store_detail')}</td>
                        <td><strong>{activeUser.info.store_detail}</strong></td>
                    </tr>
                    <tr>
                        <td className="text-black-50">{t('phone_number')}</td>
                        <td><strong>{activeUser.info.phone}</strong></td>
                    </tr>
                    <tr>
                        <td className="text-black-50">{t('whatsapp')}</td>
                        <td><strong>{activeUser.info.whatsapp}</strong></td>
                    </tr>
                    <tr>
                        <td className="text-black-50">{t('line_app')}</td>
                        <td><strong>{activeUser.info.line}</strong></td>
                    </tr>
                    <tr>
                        <td className="text-black-50">{t('fb')}</td>
                        <td><strong>{activeUser.info.fb}</strong></td>
                    </tr>
                    <tr>
                        <td className="text-black-50">{t('verify_member_status')}</td>
                        <td>{activeUser.info.verify_status === 1
                            ? <div className="badge bg-success badge-pill"><FaStar /> {t('verified_member')}</div>
                            : <div>
                                <div className="badge bg-warning badge-pill text-dark"><MdPending /> {t('pending_verify_member')}</div>
                                <br />
                                <small className="text-black-50">{t('pending_verify_member_help')}</small>
                            </div>
                        }</td>
                    </tr>
                </tbody>
            </table>
        )
    }

    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-light bg-white">
                <div className="container-fluid px-0">
                    <h3 className="fw-bold my-3 mr-lg-3">{t('my_profile_info')}</h3>
                </div>
            </nav>
            <hr className="mt-0" />

            <div className="d-flex justify-content-between">
                <h6 className="text-black-50">{t('my_profile_info')}</h6>
                <div>
                    {!isFormOpen ? <PsButton color="secondary" size="sm" handleClick={() => switchEditFormAndInfo(true)}><AiFillEdit /> {t('edit')}</PsButton> : <PsButton color="danger" size="sm" handleClick={() => switchEditFormAndInfo(false)}><MdOutlineCancel /> {t('cancel')}</PsButton>}
                </div>
            </div>

            {!isFormOpen ? <ProfileInfoTable /> : <ProfileInfoEdit switchEditFormAndInfo={switchEditFormAndInfo} />}
        </>
    )
}
