import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import api from '../../js/api';
import alert from '../../js/alert';
import PsButton from '../element/PsButton';
import HookFormItems from '../form/HookFormItems';
import { FiUnlock, FiEye, FiEyeOff } from 'react-icons/fi';
import { NavLink } from 'react-router-dom';
import img_logo from './../../logo.png';
import SecondFooter from '../nav/SecondFooter';
import './../../css/page-focus-center.css';

export default function PageResetPassword(props) {
    const { code } = useParams();
    const { t } = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { register, control, handleSubmit, reset, formState: { errors, isDirty, isValid }, } = useForm({ reValidateMode: 'onChange', });
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordFieldType, setPasswordFieldType] = useState("");
    const [confirmPasswordFieldType, setConfirmPasswordFieldType] = useState("");
    const [errorInfo, setErrorInfo] = useState({});

    const inputFields = [
        {
            label: t('new_password'),
            requiredOptions: {
                required: t('please_enter_field_value'),
                minLength: {
                    value: 6,
                    message: t('password_format_length_warning')
                },
                maxLength: {
                    value: 60,
                    message: t('password_format_length_warning')
                }
            },
            attr: {
                name: "password",
                type: passwordFieldType,
                inputMode: undefined,
                placeholder: t('new_password'),
                id: "form-password-field",
                autoComplete: "off",
            },
            controlTypeToggle: {
                a: "password",
                b: "text",
                refState: passwordFieldType,
                setRefState: setPasswordFieldType,
                aIcon: <FiEye />,
                bIcon: <FiEyeOff />
            },
            callback: {
                onChange: (e) => {
                    setPassword(e.target.value);
                }
            }
        },
        {
            label: t('confirm_password'),
            requiredOptions: {
                required: t('please_enter_field_value'),
                minLength: {
                    value: 6,
                    message: t('password_format_length_warning')
                },
                maxLength: {
                    value: 60,
                    message: t('password_format_length_warning')
                },
                pattern: {
                    value: new RegExp(`^${password}$`),
                    message: t('password_not_match')
                }
            },
            attr: {
                name: "confirm_password",
                type: confirmPasswordFieldType,
                inputMode: undefined,
                placeholder: t('confirm_password'),
                id: "form-confirm-password-field",
                autoComplete: "off",
            },
            controlTypeToggle: {
                a: "password",
                b: "text",
                refState: confirmPasswordFieldType,
                setRefState: setConfirmPasswordFieldType,
                aIcon: <FiEye />,
                bIcon: <FiEyeOff />
            },
            callback: {
                onChange: (e) => {
                    setConfirmPassword(e.target.value);
                }
            }
        },
    ];

    const onSubmit = async (data) => {
        try {
            setErrorInfo({});
            setIsSubmitting(true);
            const aData = { ...data };
            aData['type'] = 1;
            aData['code'] = code;
            const submit = await api.post(`reset-password`, aData);
            alert.toastSuccess(alert.tryGetSuccessMessage(submit));
            reset();
        } catch (error) {
            onError(error);
        } finally {
            setIsSubmitting(false);
        }
    }

    const onError = (err) => {
        const errMessage = alert.tryGetErrorMessage(err);
        console.log(errMessage)
        setErrorInfo({ message: errMessage });
        throw new Error(errMessage);
    }

    return (
        <div id="app" className="page-focus-center">
            <div className="container-lg my-auto">
                <div className="row justify-content-center">
                    <div className="col-md-8 col-lg-6">
                        <div className="page-form-logo row justify-content-center">
                            <div className="col-8 col-sm-6 col-lg-4">
                                <NavLink to={{ pathname: '/' }}>
                                    <img src={img_logo} alt={process.env.REACT_APP_NAME} className="w-100 mb-4" />
                                </NavLink>
                            </div>
                        </div>
                        <div className="rounded bg-white shadow px-4 py-5">
                            <h3>{t('change_password')}</h3>
                            <hr />
                            <form onSubmit={handleSubmit(onSubmit, onError)} id="form-reset-password" autoComplete="off">
                                <fieldset disabled={isSubmitting}>
                                    <div className="row">
                                        <HookFormItems formFields={inputFields} register={register} control={control} errors={errors} isDirty={isDirty} isValid={isValid} />
                                        <div className="col-12">
                                            <PsButton type="submit" display="block" icon={<FiUnlock />} isLoading={isSubmitting}>{t('change_password')}</PsButton>
                                        </div>
                                        {(errorInfo.message) &&
                                            <div className="col-12">
                                                <div className="alert alert-danger mt-3" role="alert">
                                                    {errorInfo.message}
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </fieldset>
                            </form>
                            <div className="d-flex justify-content-between mt-3">
                                <NavLink to={{ pathname: '/login' }} className="text-decoration-none">{t('login')}</NavLink>
                                <NavLink to={{ pathname: '/register' }} className="text-decoration-none">{t('register')}</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SecondFooter />
        </div>
    )
}
