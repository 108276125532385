import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import api from '../../js/api';
import alert from '../../js/alert';
import PsButton from '../element/PsButton';
import HookFormItems from '../form/HookFormItems';
import { BiErrorCircle, BiCheckCircle } from 'react-icons/bi';
import { NavLink } from 'react-router-dom';
import img_logo from './../../logo.png';
import SecondFooter from '../nav/SecondFooter';
import { useLoadingContext } from '../../context/LoadingContext';

export default function PageVerifyAccount(props) {
    const { code } = useParams();
    const { t } = useTranslation();
    const { presentLoading, dissmissLoading } = useLoadingContext();
    const [verifyState, setVerifyState] = useState("");
    const [resultInfo, setResultInfo] = useState({});

    useEffect(() => {
        verifyAccount();
    }, []);

    const verifyAccount = async () => {
        try {
            setVerifyState("fetching");
            presentLoading();
            const submit = await api.post(`verify-account`, {code: code});
            setResultInfo({message: alert.tryGetSuccessMessage(submit)});
            setVerifyState("success");
        } catch (error) {
            setResultInfo({message: alert.tryGetErrorMessage(error)});
            setVerifyState("error");
        } finally {
            dissmissLoading();
        }
    }
    
    return (
        <div id="app" className="flex-column bg-light">
            <div className="container-lg my-auto">
                <div className="row justify-content-center">
                    <div className="col-md-8 col-lg-6">
                        <div className="page-form-logo row justify-content-center">
                            <div className="col-8 col-sm-6 col-lg-4">
                                <NavLink to={{ pathname: '/' }}>
                                    <img src={img_logo} alt={process.env.REACT_APP_NAME} className="w-100 mb-4" />
                                </NavLink>
                            </div>
                        </div>
                        <div className="rounded bg-white shadow px-4 py-5">
                            <h3>{t('verify_account')}</h3>
                            <hr />
                            {
                            {
                                "error": 
                                <div className="alert alert-danger" role="alert">
                                <BiErrorCircle/> {resultInfo.message}
                              </div>,
                               "success": 
                               <div className="alert alert-success" role="alert">
                               <BiCheckCircle/> {resultInfo.message}
                             </div>
                            }[verifyState]
                            }
                            <div className="d-flex justify-content-between mt-3">
                                <NavLink to={{ pathname: '/login' }} className="text-decoration-none">{t('login')}</NavLink>
                                <NavLink to={{ pathname: '/' }} className="text-decoration-none">{t('home')}</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SecondFooter />
        </div>
    )
}
