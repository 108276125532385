import React, { createContext, useContext, useState, useRef } from 'react';

export const LayoutContext = createContext();

function LayoutContextProvider(props) {
    const headerRef = useRef();
    const sidebarRef = useRef();
    const [sidebarToggle, setSidebarToggle] = useState(false);
    const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

    const toggleSidebarHandler = () => {
        setSidebarToggle(!sidebarToggle);
    }

    const collapsedSidebarHandler = () => {
        setSidebarCollapsed(!sidebarCollapsed);
    }

    const toggleOffSidebar = () => {
        if(sidebarToggle){
            toggleSidebarHandler();
        }
    }

    const value = { sidebarToggle, sidebarCollapsed, toggleSidebarHandler, collapsedSidebarHandler, toggleOffSidebar, sidebarRef, headerRef };

    return (
        <LayoutContext.Provider value={value}>
            {props.children}
        </LayoutContext.Provider>
    )
}

function useLayoutContext() {
    const context = useContext(LayoutContext)
    if (context === undefined) {
        throw new Error('useLayoutContext must be used within a LayoutProvider')
    }
    return context
}

export { useLayoutContext  }

export default LayoutContextProvider;