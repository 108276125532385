import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import FormAddPhoneNumber from './FormAddPhoneNumber';
import PsButton from '../../element/PsButton';
import { FaTrash } from 'react-icons/fa';
import { AiOutlinePlusCircle, AiOutlineSave } from 'react-icons/ai';
import api from '../../../js/api';
import { useLoadingContext } from '../../../context/LoadingContext';
import alert from '../../../js/alert';
import { usePrefixContext } from '../../../context/PrefixContext';
import { currencyFormat } from '../../../js/utilities';

export default function MyStorePhoneNumberAdd() {
    const { t } = useTranslation();
    const [formIds, setFormIds] = useState([]);
    const refs = useRef([]);
    const { presentLoading, dismissLoading } = useLoadingContext();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [successInfo, setSuccessInfo] = useState({});
    const [errorInfo, setErrorInfo] = useState({});
    const { prefix } = usePrefixContext();

    useEffect(() => {
        if (formIds.length === 0) {
            addNewForm();
        }
    }, [formIds]);

    const addNewForm = () => {
        const timestamp = new Date().getTime();
        const tmp = formIds;
        tmp.push(timestamp);
        setFormIds([...tmp]);
    }

    const removeForm = (index) => {
        if (formIds.length <= 1) {
            return;
        }
        const tmp = formIds;
        tmp.splice(index, 1);
        setFormIds([...tmp]);
    }

    const submitAllForm = async () => {
        let hasError = false;
        const formData = [];
        for (const element of refs.current) {
            if (element === null) {
                continue;
            }
            element.setErrorInfo({});
            const errors = [];
            const uuid = element.uuid;
            const price = parseFloat(element.price);
            const packageType = element.packageType;
            const paid = element.paidType;
            const provider = element.provider;
            const numbers = element.numbers.trim();

            if (numbers.length === 0) {
                errors.push(t('please_enter_field_value'));
            }

            if (price < prefix.min_price) {
                errors.push(t('invalid_price_min', { min_price: prefix.min_price }));
            }

            if (provider === 0) {
                errors.push(t('please_select_provider'));
            }

            formData.push({
                key: uuid,
                numbers: numbers,
                package: packageType,
                paid: paid,
                provider: provider,
                price: price
            });

            if (errors.length > 0) {
                element.setErrorInfo({ message: errors.join("<br />") });
                hasError = true;
            }
        }

        if (hasError) {
            return;
        }
        // console.log(formData)
        const submit = await submitPhoneNumber(formData);
        // console.log(submit)
    }

    const submitPhoneNumber = async (formData) => {
        try {
            setErrorInfo({});
            setSuccessInfo({});
            setIsSubmitting(true);
            presentLoading();
            const submit = await api.post(`my-store/phone-number`, formData);
            await onSubmitSuccess(submit.data);
            return submit;
        } catch (error) {
            onSubmitError(error.response);
            return error.response;
        } finally {
            dismissLoading();
            setIsSubmitting(false);
        }
    }

    const onSubmitSuccess = async (result) => {
        await clearForms();
        setSuccessInfo(result);
    }

    const onSubmitError = (error) => {
        if (error) {
            alert.toastError(alert.tryGetErrorMessage(error));
            // return;
        };

        const errors = error.data;
        Object.entries(errors.details).map(([key, value]) => {
            for (const element of refs.current) {
                if (element === null) {
                    continue;
                }
                const uuid = element.uuid;
                if (uuid === parseInt(key)) {
                    if (value.status !== true) {
                        element.setErrorInfo({ message: value.message });
                    }
                }
            }
        });

        setErrorInfo({ message: errors.message });
    }

    const clearForms = async () => {
        await setErrorInfo({});
        await setSuccessInfo({});
        await setFormIds([]);
    }
    return (
        <div>
            <div className="d-flex justify-content-between mb-3 align-items-center">
                <h5 className="text-black-50">{t('add_phone_number_manual')}</h5>
            </div>
            {Object.keys(errorInfo).length > 0 ?
                <div className="alert alert-danger alert-dismissible fade show" role="alert">
                    <strong>{errorInfo.message}</strong>
                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setErrorInfo({})}></button>
                    {/* <hr /> */}
                </div>
                : null}

            {Object.keys(successInfo).length > 0 ?
                <div className="alert alert-success alert-dismissible fade show" role="alert">
                    <strong>{successInfo.message}</strong>
                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setSuccessInfo({})}></button>
                    <hr />
                    <table className="table table-sm mb-0 table-striped">
                        <thead>
                            <tr>
                                <th>{t('phone_number')}</th>
                                <th>{t('price')}</th>
                                <th>{t('sim_provider')}</th>
                                <th>{t('sim_package_type')}</th>
                                <th>{t('sim_paid_type')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {successInfo.numbers.map((item) => {
                                return (
                                    <tr key={`success-add-phone-number-row-${item.number}`}>
                                        <td>{item.number}</td>
                                        <td>{currencyFormat(item.price)}</td>
                                        <td>{prefix.sim_provider[item.provider].provider_name}</td>
                                        <td>{prefix.sim_package_type[item.package].name}</td>
                                        <td>{prefix.sim_paid_type[item.paid].name}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>

                </div>
                : null}
            {
                formIds.map((item, index) => {
                    return (
                        <div className="d-flex bg-light mb-3 p-3" key={`form-add-phone-number-${item}`}>
                            <div className="flex-fill">
                                <FormAddPhoneNumber uuid={item} ref={(element) => { refs.current[index] = element }} prefix={{ simPackageType: prefix.sim_package_type, simProvider: prefix.sim_provider, simPaidType: prefix.sim_paid_type }} />
                            </div>
                            &nbsp;
                            <div className="pt-4 pb-2">
                                <PsButton isDisabled={formIds.length <= 1} containerClassName="mt-2" color="danger" icon={<FaTrash />} handleClick={() => removeForm(index)}></PsButton>

                            </div>
                        </div>
                    )
                })
            }
            <p className="text-black-50">
                {t('add_phone_number_instruction_1')}
            </p>
            <div>
                <PsButton handleClick={() => addNewForm()} color="tertiary" isDisabled={isSubmitting}><AiOutlinePlusCircle /> {t('add_form_row')}</PsButton>
                &nbsp;
                <PsButton handleClick={() => submitAllForm()} color="success" isDisabled={isSubmitting}><AiOutlineSave /> {t('submit')}</PsButton>
            </div>
        </div>
    )
}
