import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../context/AuthContext';
import HookFormItems from '../../form/HookFormItems';
import api from '../../../js/api';
import alert from '../../../js/alert';
import { RiSave3Fill } from 'react-icons/ri';
import PsButton from '../../element/PsButton';

export default function ProfileInfoEdit(props) {
    const { t } = useTranslation();
    const { activeUser, fetchUserData } = useAuthContext();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const onSubmit = async (data) => {
        setIsSubmitting(true);
        try {
            const submit = await api.put(`profile-info`, data);
            await fetchUserData();
            alert.toastSuccess(alert.tryGetSuccessMessage(submit));
            props.switchEditFormAndInfo(false);
        } catch (error) {
            alert.toastError(alert.tryGetErrorMessage(error));
        } finally {
            setIsSubmitting(false);
        }
    }

    const onError = (err) => {
    }

    
    const { register, control, handleSubmit, reset, formState: { errors, isDirty, isValid, isSubmitted }, } = useForm({ reValidateMode: 'onChange', });

    const inputFields = [
        {
            label: t('fullname'),
            requiredOptions: {
                required: t('please_enter_field_value'),
                maxLength: {
                    value: 100,
                    message: t('form_default_text_length_format_warning')
                }
            },
            attr: {
                name: "fullname",
                type: "text",
                inputMode: undefined,
                placeholder: t('fullname'),
                id: "form-sp-fullname-field",
                autoComplete: "off",
            },
            defaultValue: activeUser.info.fullname,
            className: {
                controlGroup: "col-12 col-lg-6"
            }
        },
        {
            label: t('store_name'),
            requiredOptions: {
                maxLength: {
                    value: 100,
                    message: t('form_default_text_length_format_warning')
                }
            },
            attr: {
                name: "store_name",
                type: "text",
                inputMode: undefined,
                placeholder: t('store_name'),
                id: "form-sp-store-name-field",
                autoComplete: "off",
            },
            defaultValue: activeUser.info.store_name,
            className: {
                controlGroup: "col-12 col-lg-6"
            }
        },
        {
            label: t('store_detail'),
            requiredOptions: {
                maxLength: {
                    value: 1000,
                    message: t('form_default_textarea_length_format_warning')
                }
            },
            attr: {
                name: "store_detail",
                type: "textarea",
                inputMode: undefined,
                placeholder: t('store_detail'),
                id: "form-sp-store-detail-field",
                autoComplete: "off",
            },
            defaultValue: activeUser.info.store_detail,
            className: {
                controlGroup: "col-12"
            }
        },
        // {
        //     label: t('email'),
        //     requiredOptions: {
        //         required: t('please_enter_field_value'),
        //         maxLength: {
        //             value: 100,
        //             message: t('form_default_text_length_format_warning')
        //         }
        //     },
        //     attr: {
        //         name: "email",
        //         type: "email",
        //         inputMode: undefined,
        //         placeholder: t('email'),
        //         id: "form-sp-email-field",
        //         autoComplete: "off",
        //     },
        //     defaultValue: activeUser.info.email,
        //     className: {
        //         controlGroup: "col-12 col-lg-6"
        //     }
        // },
        {
            label: t('phone_number'),
            requiredOptions: {
                required: t('please_enter_field_value'),
                maxLength: {
                    value: 100,
                    message: t('form_default_text_length_format_warning')
                },
                pattern: {
                    value: /^[-0-9()+,]+$/,
                    message: t('error')
                }
            },
            attr: {
                name: "phone",
                type: "text",
                inputMode: undefined,
                placeholder: t('phone_number'),
                id: "form-sp-phone-field",
                autoComplete: "off",
            },
            defaultValue: activeUser.info.phone,
            className: {
                controlGroup: "col-12 col-lg-6"
            },
            help: t('phone_number_format_help')
        },
        {
            label: t('whatsapp'),
            requiredOptions: {
                maxLength: {
                    value: 100,
                    message: t('form_default_text_length_format_warning')
                }
            },
            attr: {
                name: "whatsapp",
                type: "text",
                inputMode: undefined,
                placeholder: t('whatsapp'),
                id: "form-sp-whatsapp-field",
                autoComplete: "off",
            },
            defaultValue: activeUser.info.whatsapp,
            className: {
                controlGroup: "col-12 col-lg-6"
            }
        },
        {
            label: t('line_app'),
            requiredOptions: {
                maxLength: {
                    value: 100,
                    message: t('form_default_text_length_format_warning')
                }
            },
            attr: {
                name: "line",
                type: "text",
                inputMode: undefined,
                placeholder: t('line_app'),
                id: "form-sp-line-field",
                autoComplete: "off",
            },
            defaultValue: activeUser.info.line,
            className: {
                controlGroup: "col-12 col-lg-6"
            }
        },
        {
            label: t('fb'),
            requiredOptions: {
                maxLength: {
                    value: 100,
                    message: t('form_default_text_length_format_warning')
                }
            },
            attr: {
                name: "fb",
                type: "text",
                inputMode: undefined,
                placeholder: t('fb'),
                id: "form-sp-fb-field",
                autoComplete: "off",
            },
            defaultValue: activeUser.info.fb,
            className: {
                controlGroup: "col-12 col-lg-6"
            }
        },
    ];

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit, onError)} id="form-sp" autoComplete="off">
                <fieldset disabled={isSubmitting}>
                    <div className="row m-0">
                        <HookFormItems formFields={inputFields} register={register} control={control} errors={errors} isDirty={isDirty} isValid={isValid} />

                        <div className="col-12">
                            <PsButton type="submit" icon={<RiSave3Fill />} isLoading={isSubmitting}>{t('submit')}</PsButton>&nbsp;&nbsp;
                        </div>
                    </div>
                </fieldset>
            </form>
        </div>
    )
}
