import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../js/api';
import { usePrefixContext } from '../context/PrefixContext';
import PhoneNumberItem from './PhoneNumberItem';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { AiFillCaretRight, AiFillCaretLeft, AiFillStar } from 'react-icons/ai';
import LoadingMiniItem from './LoadingMiniItem';

export default function PhoneNumberRecommended() {
    const { t } = useTranslation();
    const [dataList, setDataList] = useState([]);
    const { prefix, isCompleted } = usePrefixContext();
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        if (isCompleted) {
            fetchData();
        }
    }, [isCompleted]);

    const fetchData = async () => {
        try {
            setIsFetching(true);
            const fetch = await api.get(`phone-number/recommended`);
            setDataList(fetch.data);
        } catch (error) {

        } finally {
            setIsFetching(false);
        }
    }

    const NextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <button onClick={onClick} className="arrow next"><AiFillCaretRight /></button>
        )
    }

    const PrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <button onClick={onClick} className="arrow prev"><AiFillCaretLeft /></button>
        )
    }

    const slideSettings = {
        dots: false,
        fade: false,
        infinite: dataList.length > 3,
        speed: 1000,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        autoplay: true,
        autoplaySpeed: 10000,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 1,
        centerMode: false,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    initialSlide: 3
                }
            },
        ]
    }

    return (
        <>
            {
                isFetching ? <LoadingMiniItem /> :
                    <>
                        {dataList.length > 0 ?
                            <div className="phone-number-recommended-container">
                                <Slider {...slideSettings}>
                                    {dataList.map((item) => {
                                        return <PhoneNumberItem {...item} key={`phone-number-recommended-item-${item.number}`} />
                                    })}
                                </Slider>
                            </div>
                            :
                            <div className="py-3"><p>{t('no_data_found')}</p></div>}
                    </>
            }
        </>
    )
}
