import React, { useState, useEffect } from 'react';
import PageWrapper from './PageWrapper';
import SectionWrapper from '../SectionWrapper';
import api from '../../js/api';
import { useTranslation } from 'react-i18next';
import MainNav from '../nav/MainNav';
import PaginatedItems from '../PaginatedItems';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import PhoneNumberItem from '../PhoneNumberItem';
import LoadingMiniItem from '../LoadingMiniItem';

export default function PageAllNumber() {
    const { t } = useTranslation();
    const { page } = useParams();
    const [countAllNumber, setCountAllNumber] = useState(-1);
    const [itemsPerPage, setItemsPerPage] = useState(60);
    const [numberList, setNumberList] = useState([]);
    const [isFetching, setIsFetching] = useState(false);
    const [search, setSearch] = useSearchParams();
    const [sortBy, setSortBy] = useState(search.get('sort') === null ? "new" : search.get('sort'));
    const [currentPage, setCurrentPage] = useState(page === undefined ? 1 : page);
    const navigate = useNavigate();


    useEffect(() => {
        fetchCountAllNumber();
    }, []);

    useEffect(() => {
        setSearch({ sort: sortBy });
        fetchNumber(page);
    }, [page, sortBy]);

    const fetchCountAllNumber = async () => {
        try {
            const fetch = await api.get(`phone-number/count`);
            await setCountAllNumber(fetch.data);
        } catch (error) {
        } finally {
        }
    }

    const fetchNumber = async (_page) => {
        if(isFetching){
            return;
        }
        try {
            setIsFetching(true);
            setNumberList([]);
            _page = _page === undefined ? 1 : _page;
            const params = { page: _page, limit: itemsPerPage, sort: sortBy }
            const fetch = await api.get(`phone-number/all`, { params: params });
            setNumberList(fetch.data);
        } catch (error) {
        } finally {
            setIsFetching(false);
        }
    }

    const onPageChange = (newPage, newOffset) => {
        const n = parseInt(newPage)+1;
        setCurrentPage(n);
        console.log("change", newPage, n)
        navigate(`/all-number/${n}${'?sort='+sortBy}`);
    }

    return (
        <PageWrapper>
            <SectionWrapper>
                {/* <div className="mb-5">
                    <MainNav pageTitle={t('phone_number_recommended')}></MainNav>
                    <PhoneNumberRecommended />
                </div> */}

                <MainNav>
                <h3 className="m-0 fw-bold">{t('all_phone_number')}</h3>
                    <div>
                        <select name="" id="" className="form-select" value={sortBy} onChange={(e) => setSortBy(e.target.value)}>
                            <option value="new">{t('sort_new')}</option>
                            <option value="d_price">{t('sort_desc_price')}</option>
                            <option value="a_price">{t('sort_asc_price')}</option>
                            <option value="a_provider">{t('sort_asc_provider')}</option>
                        </select>
                    </div>
                </MainNav>
                <div className="row">
                    {numberList.map((item) => {
                        return <div className="col-sm-6 col-lg-4 col-xl-3 mb-3" key={`phone-number-all-result-item-${item.number}`}>
                            <PhoneNumberItem {...item} />
                        </div>
                    })}
                </div>
                {countAllNumber > -1 &&
                    <PaginatedItems itemsLength={countAllNumber} itemsPerPage={itemsPerPage} initPage={currentPage-1} onPageChange={onPageChange} />
                }
                {isFetching && <LoadingMiniItem />}
            </SectionWrapper>
        </PageWrapper>

    )
}
