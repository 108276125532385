import React from 'react';
import { MdFileCopy } from 'react-icons/md';
import { BsTelephoneFill, BsLine } from 'react-icons/bs';
import { HiExternalLink } from 'react-icons/hi';
import { FaEnvelope, FaFacebook } from 'react-icons/fa';
import alert from '../../../js/alert';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import PsButton from '../../element/PsButton';
import { t } from 'i18next';
import { IoLogoWhatsapp } from 'react-icons/io';

export default function ContactInfoRow(props) {
    const { url, name, image, type } = props;

    const onCopySuccess = () => {
        alert.toastSuccess(t('copy_success'))
    }
    return (
        <div className="row text-start mb-3 gx-2 align-items-end">
            <div className="mb-3 mb-sm-0 col-12 col-sm-6 col-lmd-6 col-lg-5">
                {name && <label className={`form-label text-app-${type} fw-bold`}>{name}</label>}
                <input type="text" readOnly value={url} className="form-control" />
            </div>
            <div className="col-12 col-sm-6">
                <CopyToClipboard text={url} onCopy={() => onCopySuccess()}>
                    <button className="btn btn-primary me-2"><MdFileCopy /> {t('copy')}</button>
                </CopyToClipboard>
                {
                    {
                        "phone": <>
                            <a href={`tel:${url}`} className="btn btn-tertiary"><BsTelephoneFill /> {t('call')}</a>
                        </>,
                        "email": <>
                            <a href={`mailto:${url}`} className="btn btn-info"><FaEnvelope /> {t('email')}</a>
                        </>,
                        "whatsapp": <>
                            <a href={`https://api.whatsapp.com/send?phone=${url}`} className="btn btn-app-whatsapp" title={name ? name : ""}><IoLogoWhatsapp /> {t('whatsapp')}</a>
                        </>,
                        "fb": <>
                            <a href={`${url}`} className="btn btn-app-fb" title={name ? name : ""}><FaFacebook /> {t('fb')}</a>
                        </>,
                        "line": <>
                            <a href={`${url}`} className="btn btn-app-line" title={name ? name : ""}><BsLine /> {t('line_app')}</a>
                        </>,
                    }[type]
                }
            </div>
            {image && <div className="my-3 col-12"><img src={image} style={{width: '150px'}} /></div>}

        </div>
    )
}
