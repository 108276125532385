import React from 'react';
import { CgSpinnerTwo } from 'react-icons/cg';

export default function PsButtonInner(props) {
    return (
        <button type={props.type ? props.type : "button"}
            className={`btn btn-${props.color ? props.color : "primary"} 
                ${props.textColor ? 'text-'.concat(props.textColor) : ''}
                ${props.size ? "btn-".concat(props.size) : ''}
                ${props.display === "block" ? 'w-100' : ''}
                ${props.className ? props.className : ''}`}
            disabled={props.isLoading === true || props.isDisabled === true}
            onClick={props.handleClick}>
            {
                props.isLoading === true
                    ? <><CgSpinnerTwo className="btn-loading-icon" />{props.children && <>&nbsp;</>}</>
                    : props.icon && <>{props.icon}{props.children && <>&nbsp;</>}</>
            }
            {props.children}
        </button>
    )
}
