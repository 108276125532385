import React, { useRef, useState, useEffect } from 'react';
import PageWrapper from './PageWrapper';
import SectionWrapper from './../SectionWrapper';
import { useTranslation } from 'react-i18next';
import PhoneNumberSearchForm from '../PhoneNumberSearchForm';
import './../../css/style.css';
import { usePrefixContext } from '../../context/PrefixContext';
import PhoneNumberRecommended from '../PhoneNumberRecommended';
import PhoneNumberItem from '../PhoneNumberItem';
import { currencyFormat } from '../../js/utilities';
import { BsSearch } from 'react-icons/bs';
import LoadingMiniItem from '../LoadingMiniItem';
import { AiFillStar } from 'react-icons/ai';
import PaginatedItems from '../PaginatedItems';
import img_gdb from './../../img/gdb3.png';

export default function PageHome() {
  const { t } = useTranslation();
  const { prefix, isCompleted } = usePrefixContext();
  const searchFormRef = useRef();
  const searchResultRef = useRef();
  const [phoneNumberSearchResult, setPhoneNumberSearchResult] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [searchResultItemsPerPage, setSearchResultItemsPerPage] = useState(32);
  const [searchResultItemsToDisplay, setSearchResultItemsToDisplay] = useState([]);
  // const [searchResultOffset, setSearchResultOffset] = useState(0);

  useEffect(() => {
    if (phoneNumberSearchResult !== null) {
      searchResultRef.current.scrollIntoView();
      setSearchResultItemsToDisplay(phoneNumberSearchResult.slice(0, searchResultItemsPerPage));
    }
  }, [phoneNumberSearchResult]);

  const onBeforeSearch = async () => {
    setPhoneNumberSearchResult([]);
  }

  const onSearchSuccess = async (data) => {
    setPhoneNumberSearchResult(data);
  }

  const searchResultPageChange = (newPage, newOffset) => {
    setSearchResultItemsToDisplay(phoneNumberSearchResult.slice(newOffset, newOffset + searchResultItemsPerPage));
  }

  return (
    <PageWrapper>
      <SectionWrapper>
        <h1 className="h3 fw-bold">{t('home_welcome_message')}</h1>
      </SectionWrapper>

      <>
        <SectionWrapper>
          <PhoneNumberSearchForm ref={searchFormRef} onBeforeSearch={onBeforeSearch} onSearchSuccess={onSearchSuccess} setIsFetching={setIsSearching} />
        </SectionWrapper>
        <div className="bg-tertiary-x-text-white">
          <div className="container-lg py-3 mt-4 mb-2">
            <div className="recommended-title">
              <img src={img_gdb} />
              <div className="bg">
                <h1 className="fw-bold" style={{ fontSize: '2.5rem' }}>
                  {t('phone_number_recommended')}
                </h1>
              </div>
            </div>

            {/* <div className="d-flex justify-content-center align-items-center">
            <img src={img_gdb} style={{ width: '45px' }} className="me-3" />
            <h1 className="fw-bold" style={{fontSize: '3rem'}}>
              {t('phone_number_recommended')}
            </h1>
          </div> */}
          </div>
        </div>
        <SectionWrapper>

          <PhoneNumberRecommended />
        </SectionWrapper>
        <SectionWrapper>
          <div ref={searchResultRef}>
            {phoneNumberSearchResult !== null ?
              <div className="my-3">
                <h1 className="h3 fw-bold"><BsSearch /> {t('search_result')}</h1>
                <div className="bg-white shadow-sm border rounded p-3">
                  {!isSearching ?
                    <>
                      <div className="d-inline-block bg-primary px-4 py-2 mb-3">
                        <h4 className="m-0">{t('search_result_found_n', { n: currencyFormat(phoneNumberSearchResult.length, 0) })}</h4>
                      </div>
                      <div className="row">
                        {searchResultItemsToDisplay.map((item, index) => {
                          return <div className="col-sm-6 col-lg-4 col-xl-3 mb-3" key={`phone-number-search-result-item-${item.number}`}>
                            <PhoneNumberItem {...item} />
                          </div>
                        })}
                      </div>
                      {phoneNumberSearchResult.length > searchResultItemsPerPage &&
                        <PaginatedItems itemsLength={phoneNumberSearchResult.length} itemsPerPage={searchResultItemsPerPage} initPage={0} onPageChange={searchResultPageChange} />
                      }
                    </> :
                    <LoadingMiniItem />
                  }
                </div>
              </div>
              : null
            }
          </div>

        </SectionWrapper>
      </>
    </PageWrapper>
  )
}
