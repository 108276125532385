import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import api from '../../../js/api';
import DataTable from 'react-data-table-component';
import { usePrefixContext } from '../../../context/PrefixContext';
import PsButton from '../../element/PsButton';
import { FaSignal, FaWallet, FaTrash } from 'react-icons/fa';
import { MdWifiCalling3 } from 'react-icons/md';
import { BsSearch } from 'react-icons/bs';
import { IoRefresh } from 'react-icons/io5';
import { AiFillEdit, AiOutlineSave } from 'react-icons/ai';
import { dateDefaultFormat, currencyFormat } from '../../../js/utilities';
import PhoneNumberFormat from '../../element/PhoneNumberFormat';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import FormAddPhoneNumber from './FormAddPhoneNumber';
import alert from '../../../js/alert';
import { useLoadingContext } from '../../../context/LoadingContext';
import { NavLink } from 'react-router-dom';

export default function MyStorePhoneNumberRoot() {
  const { t } = useTranslation();
  const mySwal = withReactContent(Swal);
  const formRef = useRef();
  const { presentLoading, dismissLoading } = useLoadingContext();
  const [isFetching, setIsFetching] = useState(false);
  const [dataList, setDataList] = useState([]);
  const { prefix } = usePrefixContext();
  const [filterText, setFilterText] = useState("");
  const [filterProvider, setFilterProvider] = useState(0);
  const [filterPackage, setFilterPackage] = useState(0);
  const [filterPaid, setFilterPaid] = useState(0);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const filteredItems = dataList.filter(
    (item) => {
      return (
        (item.provider_id && (filterProvider > 0 ? item.provider_id === filterProvider : item.provider_id)) &&
        (item.number && item.number.includes(filterText.toLowerCase())) &&
        (item.package_type && (filterPackage > 0 ? item.package_type === filterPackage : item.package_type)) &&
        (item.paid_type && (filterPaid > 0 ? item.paid_type === filterPaid : item.paid_type))
      )
    },
  );

  useEffect(() => {
    fetchDataList();
  }, []);

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
        setFilterProvider(0);
        setFilterPackage(0);
        setFilterPaid(0);
      }
    };

    return (
      <div className="row gx-1 flex-fill">
        <div className="col-sm-6 col-md-4 col-lg-3 mb-2">
          <label className="form-label" htmlFor="searchr"><BsSearch /> {t('search')}</label>
          <input
            id="search"
            type="text"
            placeholder={t('search')}
            aria-label="Search Input"
            value={filterText}
            onChange={e => setFilterText(e.target.value)}
            className="form-control"
          />
        </div>

        <div className="col-sm-6 col-md-4 col-lg-3 mb-2">
          <label className="form-label" htmlFor="sim-provider"><FaSignal /> {t('sim_provider')}</label>
          <select name="sim-provider" id="sim-provider" className="form-select" value={filterProvider} onChange={(e) => setFilterProvider(parseInt(e.target.value))}>
            <option value={0}>{t('all')}</option>
            {Object.entries(prefix.sim_provider).map(([key, value]) => {
              return (<option value={value.id} key={`sim-provider-opt-${value.id}`}>{value.provider_name}</option>)
            })}
          </select>
        </div>

        <div className="col-sm-6 col-md-4 col-lg-3 mb-2">
          <label className="form-label" htmlFor="sim-package-type"><MdWifiCalling3 /> {t('sim_package_type')}</label>
          <select name="sim-package-type" id="sim-package-type" className="form-select" value={filterPackage} onChange={(e) => setFilterPackage(parseInt(e.target.value))}>
            <option value={0}>{t('all')}</option>
            {Object.entries(prefix.sim_package_type).map(([key, value]) => {
              return (<option value={value.id} key={`sim-package-type-opt-${value.id}`}>{value.name}</option>)
            })}
          </select>
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3 mb-2">
          <label className="form-label" htmlFor="sim-paid-type"><FaWallet /> {t('sim_paid_type')}</label>
          <select name="sim-paid-type" id="sim-paid-type" className="form-select" value={filterPaid} onChange={(e) => setFilterPaid(parseInt(e.target.value))}>
            <option value={0}>{t('all')}</option>
            {Object.entries(prefix.sim_paid_type).map(([key, value]) => {
              return (<option value={value.id} key={`sim-paid-type-opt-${value.id}`}>{value.name}</option>)
            })}
          </select>
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3 mb-2 mt-md-4 py-md-2 mt-lg-0 py-lg-0">
          <PsButton handleClick={handleClear} icon={<IoRefresh />}>{t('clear')}</PsButton>
        </div>
      </div>
    );
  }, [filterText, filterProvider, filterPackage, filterPaid, resetPaginationToggle]);

  const fetchDataList = async () => {
    try {
      setIsFetching(true);
      const fetch = await api.get(`my-store/phone-number`);
      setDataList(fetch.data);
    } catch (error) {
    } finally {
      setIsFetching(false);
    }
  }

  const columns = [
    {
      name: t('phone_number'),
      selector: row => <PhoneNumberFormat number={row.number} />,
      minWidth: '120px'
    },
    {
      name: t('sim_provider'),
      selector: row => row.provider_name,
    },
    {
      name: t('price'),
      selector: row => currencyFormat(row.price),
    },
    {
      name: t('store_price'),
      selector: row => (<span className="text-success">{currencyFormat(row.store_price)}</span>),
    },
    {
      name: t('sim_package_type'),
      selector: row => prefix.sim_package_type[row.package_type].name,
    },
    {
      name: t('phone_number_sum'),
      selector: row => row.number_sum,
      maxWidth: '50px'
    },
    {
      name: t('sim_paid_type'),
      selector: row => prefix.sim_paid_type[row.paid_type].name,
    },
    {
      name: t('phone_number_sale_status'),
      selector: row => row.sale_status,
      cell: row => prefix.sale_status_option[row.sale_status].name,
    },
    {
      name: t('phone_number_recommended'),
      selector: row => row.recommended_end_date,
      cell: row => (
        <>
          {row.recommended_end_date !== null ?
            <>{t('phone_number_recommended_until', { date: dateDefaultFormat(row.recommended_end_date) })}</>
            : null}
        </>
      )
    },
    {
      name: t('edit'),
      selector: row => row.id,
      minWidth: '125px',
      cell: row => (
        <div>
          <PsButton color="info" containerClassName="my-1" handleClick={() => editPhoneNumber(row)}><AiFillEdit /></PsButton>&nbsp;&nbsp;
          <PsButton color="danger" containerClassName="my-1" handleClick={() => deletePhoneNumber(row)}><FaTrash /></PsButton>
        </div>
      )
    },
  ];

  const editPhoneNumber = async (row) => {
    mySwal.fire(
      {
        title: t('edit'),
        html: <FormAddPhoneNumber uuid={row.id} ref={formRef} prefix={{ simPackageType: prefix.sim_package_type, simProvider: prefix.sim_provider, simPaidType: prefix.sim_paid_type }} />,
        showCloseButton: true,
        confirmButtonText: <><AiOutlineSave /> {t('submit')}</>,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-success',
          popup: 'swal-lg',
        },
        preConfirm: async () => {
          const uuid = formRef.current.uuid;
          const price = parseFloat(formRef.current.price);
          const packageType = formRef.current.packageType;
          const paid = formRef.current.paidType;
          const provider = formRef.current.provider;
          const numbers = formRef.current.numbers.trim();

          if (numbers.length === 0) {
            mySwal.showValidationMessage(t('please_enter_field_value'));
            return false;
          }

          if (price < prefix.min_price) {
            mySwal.showValidationMessage(t('invalid_price_min', { min_price: prefix.min_price }));
            return false;
          }

          try {
            presentLoading();
            const formData = [{
              key: uuid,
              numbers: numbers,
              package: packageType,
              paid: paid,
              provider: provider,
              price: price
            }];
            const submit = await api.put(`my-store/phone-number`, formData);
            onUpdateSuccess(submit.data, row, formData);
          } catch (error) {
            mySwal.showValidationMessage(alert.tryGetErrorMessage(error));
          } finally {
            dismissLoading();
          }
        },
        didOpen: () => {
          formRef.current.setIsEditMode(true);
          formRef.current.setNumbers(row.number);
          formRef.current.setPrice(row.price);
          formRef.current.setProvider(row.provider_id);
          formRef.current.setPaidType(row.paid_type);
          formRef.current.setPackageType(row.package_type);
        }
      }
    ).then((result) => {
      return false;
    })
  }

  const onUpdateSuccess = (result, row, formData) => {
    alert.toastSuccess(alert.tryGetSuccessMessage(result));
    const tmp = dataList;
    for (let j = 0; j < formData.length; j++) {
      let index = -1;
      for (let i = 0; i < dataList.length; i++) {
        if (dataList[i].id === formData[j].key) {
          index = i;
          break;
        }
      }

      if (index < 0) {
        return;
      }

      tmp[index].price = formData[j].price;
      tmp[index].package_type = formData[j].package;
      tmp[index].paid_type = formData[j].paid;
      tmp[index].provider_id = formData[j].provider;
      tmp[index].provider_name = prefix.sim_provider[formData[j].provider].provider_name;
      // tmp[index].sale_status = formData[j].sale_status;
    }
    setDataList([...tmp]);
  }

  const deletePhoneNumber = async ({ id, number }) => {
    mySwal.fire({
      title: <p>{t('delete_data')}</p>,
      html: (<p>
        <Trans components={{ hilight1: <span className="fw-bold" /> }}>
          {t('delete_phone_number_confirm', { number: number })}
        </Trans>
      </p>),
      icon: 'question',
      showCloseButton: true,
      confirmButtonText: (<><FaTrash /> {t('delete')}</>),
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-danger',
      }
    }).then((result) => {
      if (result.isConfirmed) {
        deletePhoneNumberProcess(id)
      }
    });
  }

  const deletePhoneNumberProcess = async (id) => {
    try {
      presentLoading();
      const submit = await api.delete(`my-store/phone-number`, { data: { 'id': id } });
      alert.toastSuccess(alert.tryGetSuccessMessage(submit));

      let index = -1;
      for (let i = 0; i < dataList.length; i++) {
        if (dataList[i].id === id) {
          index = i;
          break;
        }
      }

      if (index < 0) {
        return;
      }

      const tmp = dataList;
      tmp.splice(index, 1);
      setDataList([...tmp]);
    } catch (error) {
      alert.toastError(alert.tryGetErrorMessage(error));
    } finally {
      dismissLoading();
    }
  }

  return (
    <div>
      <ul>
        <li>
        <Trans components={{ hilight1: <span className="text-tertiary" />, hilight2: <span className="fw-bold" />, hilight3: <span className="text-tertiary fw-bold" /> }}>
          {t('phone_number_fee_charge_explain', { fee: prefix.sell_fee, price: 100, total: 100 + (100 * prefix.sell_fee / 100), currency: prefix.currency_symbol })}
        </Trans>
        </li>
        <li>
        <NavLink to={{pathname: '/how-to-ads-recommneded'}} className="fw-bold">{t('how_to_ads_recommneded')}</NavLink>
        </li>
      </ul>
      {/* <p className="mb-4">
        * <Trans components={{ hilight1: <span className="text-tertiary" />, hilight2: <span className="fw-bold" />, hilight3: <span className="text-tertiary fw-bold" /> }}>
          {t('phone_number_fee_charge_explain', { fee: prefix.sell_fee, price: 100, total: 100 + (100 * prefix.sell_fee / 100), currency: prefix.currency_symbol })}
        </Trans>
      </p>
      <p><NavLink to={{pathname: '/how-to-ads-recommneded'}} className="fw-bold">* {t('how_to_ads_recommneded')}</NavLink></p> */}
     <hr />
      <DataTable
        // title={t('faq')}
        columns={columns}
        // data={dataList}
        data={filteredItems}
        pagination
        striped
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
      // paginationDefaultPage={dataStoreContext.hasDataStore() ? dataStoreContext.dataCurrentPage : 1}
      // onChangePage={onChangePage}
      // paginationComponentOptions={{
      //     noRowsPerPage: true
      // }}
      />

    </div>
  )
}
