import React from 'react';
import './../css/loading.css';

export default function LoadingMiniItem() {
  return (
    <div className="loading-spinning-bubbles">
      <div className="bubble-container">
        <div className="bubble"></div>
      </div>
      <div className="bubble-container">
        <div className="bubble"></div>
      </div>
      <div className="bubble-container">
        <div className="bubble"></div>
      </div>
      <div className="bubble-container">
        <div className="bubble"></div>
      </div>
      <div className="bubble-container">
        <div className="bubble"></div>
      </div>
      <div className="bubble-container">
        <div className="bubble"></div>
      </div>
      <div className="bubble-container">
        <div className="bubble"></div>
      </div>
      <div className="bubble-container">
        <div className="bubble"></div>
      </div>
    </div>
  )
}
