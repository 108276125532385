import React from 'react';
import { AiOutlineCopyrightCircle } from 'react-icons/ai';
import SecondFooter from './SecondFooter';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { usePrefixContext } from '../../context/PrefixContext';
import { useAuthContext } from '../../context/AuthContext';

export default function MainFooter() {
  const { t } = useTranslation();
  const { prefix, webContact, isCompleted } = usePrefixContext();
  const { activeUser } = useAuthContext();

  return (
    <footer>
      <div className="container-fluid py-3 bg-primary small text-black-50">
        <div className="container-lg">
          <div className="row">
            <div className="col-md-4 mb-3">
              <p className="mb-1">
                <NavLink className={`text-decoration-none text-dark`} to={{ pathname: '/' }}>{t('home')}</NavLink>
              </p>
              <p className="mb-1">
                <NavLink className={`text-decoration-none text-dark`} to={{ pathname: '/all-number' }}>{t('phone_number_auspicious')}</NavLink>
              </p>
              <p className="mb-1">
                <NavLink className={`text-decoration-none text-dark`} to={{ pathname: '/contact' }}>{t('contact_us')}</NavLink>
              </p>
              <p className="mb-1">
                <NavLink className={`text-decoration-none text-dark`} to={{ pathname: '/faq' }}>{t('faq')}</NavLink>
              </p>
            </div>
            <div className="col-md-4 mb-3">
              {
                activeUser === null ? <>
                  <p className="mb-1">
                    <NavLink className={`text-decoration-none text-dark`} to={{ pathname: '/register' }}>{t('register')}</NavLink>
                  </p>
                  <p className="mb-1">
                    <NavLink className={`text-decoration-none text-dark`} to={{ pathname: '/login' }}>{t('login')}</NavLink>
                  </p>
                </> : <>
                <p className="mb-1">
                    <NavLink className={`text-decoration-none text-dark`} to={{ pathname: '/account' }}>{t('my_profile_info')}</NavLink>
                  </p>
                  <p className="mb-1">
                    <NavLink className={`text-decoration-none text-dark`} to={{ pathname: '/account/my-store/phone-number' }}>{t('phone_number_for_sale')}</NavLink>
                  </p>
                  <p className="mb-1">
                    <NavLink className={`text-decoration-none text-dark`} to={{ pathname: '/account/change-password' }}>{t('change_password')}</NavLink>
                  </p>
                </>
              }
              <p className="mb-1">
                <NavLink className={`text-decoration-none text-dark`} to={{ pathname: '/privacy-policy' }}>{t('privacy_policy')}</NavLink>
              </p>
              <p className="mb-1">
                <NavLink className={`text-decoration-none text-dark`} to={{ pathname: '/how-to-register' }}>{t('how_to_register_as_sales_person')}</NavLink>
              </p>
            </div>
            <div className="col-md-4 mb-3">
              {
                isCompleted && <>
                  {
                    webContact.phone !== null &&
                    <p className="mb-1">
                      <span>{t('phone_number')}: </span>
                      {webContact.phone.split(",").map((item, index) => {
                        return <span key={`footer-contact-info-row-phone-${item}`}>
                          <a href={`tel:${item}`} className="text-decoration-none text-dark" key={`footer-contact-info-row-phone-${item}`}>{item}</a>{index !== webContact.phone.split(",").length - 1 && <>, </>}
                        </span>
                      })}
                    </p>
                  }
                  {
                    webContact.email !== null &&
                    <p className="mb-1">
                      <span>{t('email')}: </span>
                      <a href={`mailto:${webContact.email}`} className="text-decoration-none text-dark" key={`footer-contact-info-row-email`}>{webContact.email}</a>
                    </p>
                  }
                  {
                    webContact.whatsapp !== null &&
                    <p className="mb-1">
                      <span>{t('whatsapp')}: </span>
                      <a href={`https://api.whatsapp.com/send?phone=${webContact.whatsapp}`} className="text-decoration-none text-dark" key={`footer-contact-info-row-whatsapp`}>{webContact.whatsapp_name}</a>
                    </p>
                  }
                  {
                    webContact.fb !== null &&
                    <p className="mb-1">
                      <span>{t('fb')}: </span>
                      <a href={`${webContact.fb}`} className="text-decoration-none text-dark" key={`footer-contact-info-row-fb`}>{webContact.fb_name}</a>
                    </p>
                  }
                  {
                    webContact.line !== null &&
                    <p className="mb-1">
                      <span>{t('line_app')}: </span>
                      <a href={`${webContact.line}`} className="text-decoration-none text-dark" key={`footer-contact-info-row-line`}>{webContact.line_name}</a>
                    </p>
                  }
                </>
              }
            </div>

            <div className="col-12 text-black">
              Copryright  <AiOutlineCopyrightCircle /> 2022 {process.env.REACT_APP_NAME}
            </div>
          </div>
        </div>
      </div>
      {/* <SecondFooter/> */}
    </footer>
  )
}
