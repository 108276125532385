import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation, Trans } from 'react-i18next';
import { FiLogIn, FiEye, FiEyeOff } from 'react-icons/fi';
import HookFormItems from './HookFormItems';
import PsButton from '../element/PsButton';
import { useAuthContext } from '../../context/AuthContext';
import alert from '../../js/alert';
import api from '../../js/api';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const FormRegister = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const authContext = useAuthContext();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { register, control, handleSubmit, formState: { errors, isDirty, isValid }, } = useForm({ reValidateMode: 'onChange', });
    const [passwordFieldType, setPasswordFieldType] = useState("");
    const [confirmPasswordFieldType, setConfirmPasswordFieldType] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isAcceptTerms, setIsAcceptTerms] = useState(false);
    const [htmlTerms, setHtmlTerms] = useState(null);
    const mySwal = withReactContent(Swal);

    useImperativeHandle(ref, () => ({
        handleSubmit: handleSubmit(onSubmit, onError),
        errors: errors,
        isDirty: isDirty,
        isValid: isValid,
        isSubmitting: isSubmitting,
    }));

    const [errorInfo, setErrorInfo] = useState({});

    const inputFields = [
        {
            label: t('fullname'),
            requiredOptions: {
                required: t('please_enter_field_value'),
                maxLength: {
                    value: 100,
                    message: t('form_default_text_length_format_warning')
                }
            },
            attr: {
                name: "fullname",
                type: "text",
                inputMode: undefined,
                placeholder: t('fullname'),
                id: "form-sp-fullname-field",
                autoComplete: "off",
            },
            className: {
                controlGroup: "col-12"
            }
        },
        {
            label: t('email'),
            requiredOptions: {
                required: t('please_enter_field_value'),
                maxLength: {
                    value: 100,
                    message: t('form_default_text_length_format_warning')
                }
            },
            attr: {
                name: "email",
                type: "email",
                inputMode: undefined,
                placeholder: t('email'),
                id: "form-sp-email-field",
                autoComplete: "off",
            },
            className: {
                controlGroup: "col-12"
            },
        },
        {
            label: t('password'),
            requiredOptions: {
                required: t('please_enter_field_value'),
            },
            attr: {
                name: "password",
                type: passwordFieldType,
                inputMode: undefined,
                placeholder: t('password'),
                id: "form-login-password-field",
                autoComplete: "off",
            },
            controlTypeToggle: {
                a: "password",
                b: "text",
                refState: passwordFieldType,
                setRefState: setPasswordFieldType,
                aIcon: <FiEye />,
                bIcon: <FiEyeOff />
            },
            callback: {
                onChange: (e) => {
                    setPassword(e.target.value);
                }
            }
        },
        {
            label: t('confirm_password'),
            requiredOptions: {
                required: t('please_enter_field_value'),
                minLength: {
                    value: 6,
                    message: t('password_format_length_warning')
                },
                maxLength: {
                    value: 60,
                    message: t('password_format_length_warning')
                },
                pattern: {
                    value: new RegExp(`^${password}$`),
                    message: t('password_not_match')
                }
            },
            attr: {
                name: "confirm_password",
                type: confirmPasswordFieldType,
                inputMode: undefined,
                placeholder: t('confirm_password'),
                id: "form-confirm-password-field",
                autoComplete: "off",
            },
            controlTypeToggle: {
                a: "password",
                b: "text",
                refState: confirmPasswordFieldType,
                setRefState: setConfirmPasswordFieldType,
                aIcon: <FiEye />,
                bIcon: <FiEyeOff />
            },
            callback: {
                onChange: (e) => {
                    setConfirmPassword(e.target.value);
                }
            }
        },
        {
            label: t('store_name'),
            requiredOptions: {
                maxLength: {
                    value: 100,
                    message: t('form_default_text_length_format_warning')
                }
            },
            attr: {
                name: "store_name",
                type: "text",
                inputMode: undefined,
                placeholder: t('store_name'),
                id: "form-sp-store-name-field",
                autoComplete: "off",
            },
            className: {
                controlGroup: "col-12"
            }
        },
        {
            label: t('store_detail'),
            requiredOptions: {
                maxLength: {
                    value: 1000,
                    message: t('form_default_textarea_length_format_warning')
                }
            },
            attr: {
                name: "store_detail",
                type: "textarea",
                inputMode: undefined,
                placeholder: t('store_detail'),
                id: "form-sp-store-detail-field",
                autoComplete: "off",
            },
            className: {
                controlGroup: "col-12"
            }
        },
        {
            label: t('phone_number'),
            requiredOptions: {
                required: t('please_enter_field_value'),
                maxLength: {
                    value: 100,
                    message: t('form_default_text_length_format_warning')
                },
                pattern: {
                    value: /^[-0-9()+,]+$/,
                    message: t('error')
                }
            },
            attr: {
                name: "phone",
                type: "text",
                inputMode: undefined,
                placeholder: t('phone_number'),
                id: "form-sp-phone-field",
                autoComplete: "off",
            },
            className: {
                controlGroup: "col-12"
            },
            help: t('phone_number_format_help')
        },
        {
            label: t('whatsapp'),
            requiredOptions: {
                maxLength: {
                    value: 100,
                    message: t('form_default_text_length_format_warning')
                }
            },
            attr: {
                name: "whatsapp",
                type: "text",
                inputMode: undefined,
                placeholder: t('whatsapp'),
                id: "form-sp-whatsapp-field",
                autoComplete: "off",
            },
            className: {
                controlGroup: "col-12"
            }
        },
        {
            label: t('line'),
            requiredOptions: {
                maxLength: {
                    value: 100,
                    message: t('form_default_text_length_format_warning')
                }
            },
            attr: {
                name: "line",
                type: "text",
                inputMode: undefined,
                placeholder: t('line'),
                id: "form-sp-line-field",
                autoComplete: "off",
            },
            className: {
                controlGroup: "col-12"
            }
        },
        {
            label: t('fb'),
            requiredOptions: {
                maxLength: {
                    value: 100,
                    message: t('form_default_text_length_format_warning')
                }
            },
            attr: {
                name: "fb",
                type: "text",
                inputMode: undefined,
                placeholder: t('fb'),
                id: "form-sp-fb-field",
                autoComplete: "off",
            },
            className: {
                controlGroup: "col-12"
            }
        },
    ];


    const onSubmit = async (data) => {
        try {
            setErrorInfo({});
            setIsSubmitting(true);
            const regis = await authContext.registerUser(data);
            if (props.onSuccess) {
                props.onSuccess(regis);
            }
        } catch (error) {
            onError(error);
        } finally {
            setIsSubmitting(false);
        }
    }

    const onError = (err) => {
        const errMessage = alert.tryGetErrorMessage(err);
        if (props.onError) {
            props.onError({ message: errMessage });
        }
        setErrorInfo({ message: errMessage });
        throw new Error(errMessage);
    }

    const openTermsAndConditions = async () => {
        const html = await fetchHtmlTerms();
        mySwal.fire({
            html: html,
            confirmButtonText: <>{t('close')}</>,
            buttonsStyling: false,
            customClass: {
                confirmButton: 'btn btn-success',
                popup: 'swal-lg',
            },
        });
    }

    const fetchHtmlTerms = async () => {
        if(htmlTerms !== null) {
            return htmlTerms;
        }
        try {
            const fetch = await api.get(`terms-and-conditions`);
            await setHtmlTerms(fetch.data);
            return fetch.data;
        } catch (error) {
            alert.error(alert.tryGetErrorMessage(error));
        }
    }

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit, onError)} id="form-register" autoComplete="off">
                <fieldset disabled={isSubmitting}>
                    <div className="row">
                        <HookFormItems formFields={inputFields} register={register} control={control} errors={errors} isDirty={isDirty} isValid={isValid} />

                        <div className="col-12">
                            <div className="form-check mb-3">
                                <input className="form-check-input" type="checkbox" value="" id="termsCheck" checked={isAcceptTerms} onChange={(e) => setIsAcceptTerms(e.target.checked)} />
                                <span className="form-check-label" htmlFor="termsCheck">
                                    <Trans components={{ open: <span className="text-tertiary" onClick={() => openTermsAndConditions() } style={{cursor: 'pointer'}} /> }}>
                                    {t('accept_terms_conditions')}
                                    </Trans>
                                </span>
                            </div>
                            <PsButton type="submit" display="block" icon={<FiLogIn />} isLoading={isSubmitting} isDisabled={!isAcceptTerms}>{t('register')}</PsButton>
                        </div>

                        {(props.showErrorInfo === true && errorInfo.message) &&
                            <div className="col-12">
                                <div className="alert alert-danger mt-3" role="alert">
                                    {errorInfo.message}
                                </div>
                            </div>
                        }
                    </div>
                </fieldset>

            </form>
        </div>
    )
})

export default FormRegister