import { Navigate, Route, Outlet } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useAuthContext } from '../context/AuthContext';

export default function AppProtectedRoute() {
  const location = useLocation();
  const authcontext = useAuthContext();

  return (authcontext.isCompleted &&
    <>{
      authcontext.activeUser !== null && typeof authcontext.activeUser !== "undefined"
        ? <Outlet />
        : <Navigate to="/login" replace state={{ from: location }} />
    }</>)
}
