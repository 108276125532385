import React from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineCopyrightCircle } from 'react-icons/ai';
import { NavLink } from 'react-router-dom';

export default function SecondFooter() {
    const { t } = useTranslation();
    return (
        <footer className="bg-primary">
            <div className="container-lg py-2 small ">
                <div className="d-flex justify-content-lg-between flex-column flex-lg-row">

                    <div className="">
                        <ul className="navbar-nav d-flex flex-row">
                            <li className="nav-item me-3"><NavLink className={`nav-link p-0 text-dark`} to={{ pathname: '/' }}>{t('home')}</NavLink></li>
                            <li className="nav-item me-3"><NavLink className={`nav-link p-0 text-dark`} to={{ pathname: '/privacy-policy' }}>{t('privacy_policy')}</NavLink></li>
                            <li className="nav-item me-3"><NavLink className={`nav-link p-0 text-dark`} to={{ pathname: '/contact' }}>{t('contact_us')}</NavLink></li>
                            <li className="nav-item"><NavLink className={`nav-link p-0 text-dark`} to={{ pathname: '/faq' }}>{t('faq')}</NavLink></li>
                        </ul>
                    </div>
                    <div className="text-lg-end">
                        Copryright  <AiOutlineCopyrightCircle /> 2022 {process.env.REACT_APP_NAME}
                    </div>
                </div>
            </div>
        </footer>
    )
}