import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Ripples from 'react-ripples';
import { useTranslation, Trans } from 'react-i18next';
import { useAuthContext } from '../../context/AuthContext';
import img_logo from './../../logo.png';
import ReactTooltip from 'react-tooltip';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { FaSignOutAlt, FaRegUser } from 'react-icons/fa';


export default function MainHeader() {

  const { t } = useTranslation();
  const { isCompleted, activeUser, logOut } = useAuthContext();
  const [tooltip, showTooltip] = useState(true);

  const MySwal = withReactContent(Swal);

  const logOutHandler = () => {
    MySwal.fire({
      title: <p>{t('logout')}</p>,
      html: (<p>{t('logout_confirm')}</p>),
      icon: 'question',
      showCloseButton: true,
      confirmButtonText: (<><FaSignOutAlt /> {t('logout')}?</>),
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-danger',
      }
    }).then((result) => {
      if (result.isConfirmed) {
        logOut();
      }
    });
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-primary">
      <div className="container-lg">
        <NavLink to={{ pathname: '/' }} className="navbar-brand"><img src={img_logo} alt={process.env.REACT_APP_NAME} style={{ height: '40px' }} /></NavLink>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <NavLink className={`nav-link`} to={{ pathname: '/' }}>{t('home')}</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className={`nav-link`} to={{ pathname: '/all-number' }}>{t('phone_number_auspicious')}</NavLink>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {t('service')}
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                {activeUser === null &&
                <li><NavLink className={`dropdown-item`} to={{ pathname: '/register' }}>{t('free_register_sale')}</NavLink></li>
                }
                <li><NavLink className={`dropdown-item`} to={{ pathname: '/contact' }}>{t('contact_us')}</NavLink></li>
                <li><NavLink className={`dropdown-item`} to={{ pathname: '/faq' }}>{t('faq')}</NavLink></li>
              </ul>
            </li>
          </ul>
          <div>
            {isCompleted && <>
              {activeUser !== null
                ?
                <>
                  {/* <NavLink to={{ pathname: '/account' }} className="text-decoration-none text-black-50">
                <Trans components={{ hilight: <span className="fw-bold text-black" /> }}>{t('wellcome_username', { username: activeUser.info.fullname })}</Trans>
              </NavLink> */}
                  <div>
                    <div className="" id="navbarProfileDropdown">
                      <ul className="navbar-nav">
                        <li className="nav-item dropdown">
                          {tooltip && <ReactTooltip effect="solid" />}
                          <a className="nav-link dropdown-toggle" href="#" id="navbarProfileDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false" data-tip={t('user_menu')} onMouseEnter={() => showTooltip(true)}
                            onMouseLeave={() => {
                              showTooltip(false);
                              setTimeout(() => showTooltip(true), 50);
                            }}>
                            <Trans components={{ hilight: <span className="fw-bold text-black" /> }}>{t('wellcome_username', { username: activeUser.info.fullname })}</Trans>
                          </a>
                          <ul className="dropdown-menu dropdown-menu-light" aria-labelledby="navbarProfileDropdownMenuLink">
                            <li>
                              <NavLink to={{ pathname: '/account' }} className="dropdown-item" end>
                                {t('my_profile_info')}
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to={{ pathname: '/account/my-store/phone-number' }} className="dropdown-item">
                                {t('phone_number_for_sale')}
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to={{ pathname: '/account/change-password' }} className="dropdown-item">
                                {t('change_password')}
                              </NavLink>
                            </li>
                            <li>
                              <span className="dropdown-item" onClick={() => { logOutHandler() }} style={{cursor: 'pointer'}}>
                                {t('logout')}
                              </span>
                            </li>
                            {/* <li>
                              <NavLink to={{ pathname: '/account' }} className="dropdown-item">
                                {t('my_profile_info')}
                              </NavLink>
                            </li> */}

                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>


                </>
                :
                <Ripples>
                  <NavLink to={{ pathname: '/login' }} className="btn btn-tertiary px-5">{t('login')}</NavLink>
                </Ripples>
              }</>}
          </div>
        </div>
      </div>
    </nav>
  )
}
