import React, {useState, useEffect} from 'react'

export default function FormControlTypeToggle(props) {
    const [currentType, setCurrentType] = useState(props.a);

    useEffect(() => {
      props.setRefState(props.a);
    }, [])
    
    const onToggleControlType = () => {
        const newType = currentType === props.a ? props.b : props.a;
        setCurrentType(newType);
        props.setRefState(newType);
    }
    
  return (
    <div className="form-control-type-toggle">
      <label>
        <input type="checkbox" onChange={() => onToggleControlType()} tabIndex={-1} />
        <span className="checkmark">{props.refState === props.a ? props.aIcon : props.bIcon}</span>
      </label>
    </div>
  )
}
