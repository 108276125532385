import React, { useRef, useImperativeHandle, forwardRef } from 'react';
import Ripples from 'react-ripples';
import { CgSpinnerTwo } from 'react-icons/cg';
import './../../css/loading.css';
import PsButtonInner from './PsButtonInner';

const PsButton = forwardRef((props, ref) => {
    return (
        <>
            {props.ripples === false
                ? <PsButtonInner {...props} />
                : <Ripples className={`rounded ${props.display === 'block' ? 'd-flex' : ''} ${props.containerClassName ? props.containerClassName : ''}`} title={props.title ? props.title : "" }>
                    <PsButtonInner {...props} />
                </Ripples>}
        </>
    )
})

export default PsButton