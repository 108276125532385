import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import api from '../../js/api';
import alert from '../../js/alert';
import PsButton from '../element/PsButton';
import HookFormItems from '../form/HookFormItems';
import { FiUnlock } from 'react-icons/fi';
import SecondFooter from '../nav/SecondFooter';
import { MdStorefront } from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import img_logo from './../../logo.png';
import './../../css/page-focus-center.css';

export default function PageForgetPassword(props) {
    const { t } = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { register, control, handleSubmit, reset, formState: { errors, isDirty, isValid }, } = useForm({ reValidateMode: 'onChange', });

    const [errorInfo, setErrorInfo] = useState({});

    const inputFields = [
        {
            label: t('email'),
            requiredOptions: {
                required: t('please_enter_field_value'),
            },
            attr: {
                name: "email",
                type: "email",
                inputMode: "text",
                placeholder: t('email'),
                id: "form-fpwd-username-field",
                autoComplete: "off",
            }
        },
    ];

    const onSubmit = async (data) => {
        try {
            setErrorInfo({});
            setIsSubmitting(true);
            const submit = await api.post(`forget-password`, data);
            alert.toastSuccess(alert.tryGetSuccessMessage(submit));
            reset();
        } catch (error) {
            onError(error);
        } finally {
            setIsSubmitting(false);
        }
    }

    const onError = (err) => {
        const errMessage = alert.tryGetErrorMessage(err);
        setErrorInfo({ message: errMessage });
        throw new Error(errMessage);
    }

    return (
        <div id="app" className="page-focus-center">
            <div className="container-lg my-auto">
                <div className="row justify-content-center">
                    <div className="col-md-8 col-lg-6">
                        <div className="page-form-logo row justify-content-center">
                            <div className="col-8 col-sm-6 col-lg-4">
                                <NavLink to={{ pathname: '/' }}>
                                    <img src={img_logo} alt={process.env.REACT_APP_NAME} className="w-100 mb-4" />
                                </NavLink>
                            </div>
                        </div>
                        <div className="rounded bg-white shadow px-4 py-5">
                            <h3>{t('forget_password')}</h3>
                            <p className="text-black-50">{t('forget_password_instruction')}</p>
                            <hr />
                            <form onSubmit={handleSubmit(onSubmit, onError)} id="form-forget-password" autoComplete="off">
                                <fieldset disabled={isSubmitting}>
                                    <div className="row">
                                        <HookFormItems formFields={inputFields} register={register} control={control} errors={errors} isDirty={isDirty} isValid={isValid} />
                                        <div className="col-12">
                                            <PsButton type="submit" display="block" icon={<FiUnlock />} isLoading={isSubmitting}>{t('change_password')}</PsButton>
                                        </div>
                                        {(errorInfo.message) &&
                                            <div className="col-12">
                                                <div className="alert alert-danger mt-3" role="alert">
                                                    {errorInfo.message}
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </fieldset>
                            </form>
                            <div className="d-flex justify-content-between mt-3">
                                <NavLink to={{ pathname: '/login' }} className="text-decoration-none">{t('login')}</NavLink>
                                <NavLink to={{ pathname: '/register' }} className="text-decoration-none">{t('register')}</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SecondFooter />
        </div>
    )
}
