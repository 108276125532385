import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PsButton from '../../element/PsButton';
import { AiOutlineSave } from 'react-icons/ai';
import api from '../../../js/api';
import { useLoadingContext } from '../../../context/LoadingContext';
import alert from '../../../js/alert';
import { usePrefixContext } from '../../../context/PrefixContext';
import { currencyFormat, validatePriceFormat, validatePhoneNumber, validatePackageType, validatePaidType, validateProvider, validateSaleStatus } from '../../../js/utilities';
import HtmlContent from '../../HtmlContent';
import PhoneNumberFormat from '../../element/PhoneNumberFormat';
import * as XLSX from "xlsx";
import { RiFileExcel2Fill } from 'react-icons/ri';
import { NavLink } from 'react-router-dom';

export default function MyStorePhoneNumberAddExcel() {
  const { t } = useTranslation();
  const { presentLoading, dismissLoading } = useLoadingContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successInfo, setSuccessInfo] = useState({});
  const [errorInfo, setErrorInfo] = useState({});
  const { prefix } = usePrefixContext();
  const filePickerRef = useRef();
  const [fileData, setFileData] = useState([]);
  const [allFormData, setAllFormData] = useState([]);

  useEffect(() => {
    validateFileValue();
  }, [fileData]);

  const onSelectFile = (e) => {
    setAllFormData([]);
    setErrorInfo({});
    setSuccessInfo({});

    const [file] = e.target.files;
    const reader = new FileReader();

    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      let data = XLSX.utils.sheet_to_csv(ws, { blankrows: false, strip: true }).trim();
      data = data.split(/\n|\r/g);
      for (let i = 0; i < data.length; i++) {
        data[i] = data[i].replace(/\n|\r/g, "");
      }
      setFileData(data);
      filePickerRef.current.value = "";
    };
    reader.readAsBinaryString(file);
  }

  const validateFileValue = async () => {
    try {
      let allErrors = {};
      let tmpNumbers = [];

      const formData = [];
      for (let i = 1; i < fileData.length; i++) {
        const element = fileData[i].split(",");
        const errors = [];
        const uuid = i;
        const price = element[1];
        const numbers = element[0];

        if (!validatePriceFormat(element[1])) {
          errors.push(t("price_format_must_be_numeric"));
        } else if (parseFloat(price) < prefix.min_price) {
          errors.push(t('invalid_price_min', { min_price: prefix.min_price }));
        }

        if (!validatePhoneNumber(numbers)) {
          errors.push(t("phone_number_format_help_excel"));
        }

        const packageType = validatePackageType(element[3], prefix.sim_package_type);
        if (packageType === 0) {
          errors.push(t("invalid_package_type"));
        }

        const paid = validatePaidType(element[4], prefix.sim_paid_type);
        if (paid === 0) {
          errors.push(t("invalid_paid_type"));
        }

        const provider = validateProvider(element[2], prefix.sim_provider);
        if (provider === 0) {
          errors.push(t("invalid_provider"));
        }

        // const saleStatus = validateSaleStatus(element[5], prefix.sale_status_option);
        // if (saleStatus === 0) {
        //     errors.push(t("invalid_phone_number_sale_status"));
        // }

        if (tmpNumbers.indexOf(numbers) > -1) {
          errors.push(t("phone_number_duplicate"));
        }

        if (errors.length > 0) {
          allErrors[i] = "<ul><li>" + errors.join("</li><li>") + "</li></ul>";
        }

        tmpNumbers.push(numbers);

        formData.push({
          key: uuid,
          numbers: numbers,
          package: packageType,
          paid: paid,
          provider: provider,
          price: price,
          // sale_status: saleStatus,
        });
      }
      setErrorInfo(allErrors);
      if (Object.keys(allErrors).length === 0) {
        setAllFormData(formData);
      }
    } catch (error) {
      alert.error(t('excel_format_has_error'));
    }
  }


  const submitPhoneNumber = async () => {
    if (allFormData.length === 0) {
      alert.toastError(t("phone_number_data_is_empty") + "!");
      return;
    }
    try {
      setErrorInfo({});
      setSuccessInfo({});
      setIsSubmitting(true);
      presentLoading();
      const submit = await api.post(`my-store/phone-number`, allFormData);
      await onSubmitSuccess(submit.data);
      return submit;
    } catch (error) {
      onSubmitError(error.response);
      return error.response;
    } finally {
      dismissLoading();
      setIsSubmitting(false);
    }
  }

  const onSubmitSuccess = async (result) => {
    await clearForms();
    setSuccessInfo(result);
  }

  const onSubmitError = (error) => {
    if (error) {
      alert.toastError(alert.tryGetErrorMessage(error));
      // return;
    };

    const errors = error.data;
    let allErrors = {};
    Object.entries(errors.details).map(([key, value]) => {
      if (!value.status) {
        allErrors[key] = "<ul><li>" + value.message + "</li></ul>";
      }
    });
    setErrorInfo(allErrors);
  }

  const clearForms = async () => {
    filePickerRef.current.value = "";
    await setErrorInfo({});
    await setSuccessInfo({});
    await setAllFormData([]);
  }

  return (
    <div>
      <div className="d-flex justify-content-between mb-3 align-items-center">
        <h5 className="text-black-50">{t('add_phone_number_excel')}</h5>
      </div>
      <input type="file" name="excel-file" id="excel-file" ref={filePickerRef} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" hidden onChange={(e) => onSelectFile(e)} />

      {allFormData.length > 0 ?
        <div className="mt-4">
          <h5>{t("phone_number_will_add_list")}</h5>
          <table className="table table-sm w-100 table-striped">
            <thead>
              <tr>
                <th>{t('phone_number')}</th>
                <th>{t('price')}</th>
                <th>{t('sim_provider')}</th>
                <th>{t('sim_package_type')}</th>
                <th>{t('sim_paid_type')}</th>
              </tr>
            </thead>
            <tbody>
              {
                allFormData.map((item) => {
                  return (

                    <tr key={`all-form-phone-number-row-${item.numbers}`}>
                      <td><PhoneNumberFormat number={item.numbers} /></td>
                      <td>{currencyFormat(item.price)}</td>
                      <td>{prefix.sim_provider[item.provider].provider_name}</td>
                      <td>{prefix.sim_package_type[item.package].name}</td>
                      <td>{prefix.sim_paid_type[item.paid].name}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
        : null}

      {Object.keys(errorInfo).length > 0 ?
        <div className="mt-4">
          <div className="alert alert-danger alert-dismissible fade show" role="alert">
            <h6 className="fw-bold">{t('excel_format_has_error')}</h6>
            {
              Object.entries(errorInfo).map(([key, value]) => {
                return (<div key={`excel-row-error-${key}`}>
                  <h6 className="mb-0">{t('row_n', { n: key })})</h6>
                  <HtmlContent html={value} />
                </div>)
              })
            }
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setErrorInfo({})}></button>
          </div>
        </div>
        : null}

      {Object.keys(successInfo).length > 0 ?
        <div className="alert alert-success alert-dismissible fade show" role="alert">
          <strong>{successInfo.message}</strong>
          <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setSuccessInfo({})}></button>
          <hr />
          <table className="table table-sm mb-0 table-striped">
            <thead>
              <tr>
                <th>{t('phone_number')}</th>
                <th>{t('price')}</th>
                <th>{t('sim_provider')}</th>
                <th>{t('sim_package_type')}</th>
                <th>{t('sim_paid_type')}</th>
              </tr>
            </thead>
            <tbody>
              {successInfo.numbers.map((item) => {
                return (
                  <tr key={`success-add-phone-number-row-${item.number}`}>
                    <td>{item.number}</td>
                    <td>{currencyFormat(item.price)}</td>
                    <td>{prefix.sim_provider[item.provider].provider_name}</td>
                    <td>{prefix.sim_package_type[item.package].name}</td>
                    <td>{prefix.sim_paid_type[item.paid].name}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        : null}


      <div>
        <PsButton handleClick={() => filePickerRef.current.click()} color="tertiary" isDisabled={isSubmitting}><RiFileExcel2Fill /> {t('select_file')}</PsButton>
        &nbsp;
        <PsButton handleClick={() => submitPhoneNumber()} color="success" isDisabled={isSubmitting || allFormData.length === 0}><AiOutlineSave /> {t('submit')}</PsButton>
      </div>
      <div className="mt-3">
        <NavLink to={{ pathname: "/how-to-add-phone-number/excel" }}>{t("how_to_add_phone_number_with_excel")}</NavLink>
      </div>
    </div>
  )
}
