import React from 'react';
import NumberFormat from 'react-number-format';

export default function PhoneNumberFormat(props) {
    return (
        <>{
            props.number.length === 7+3 ?
                <NumberFormat
                    value={props.number}
                    type="text"
                    format="###-###-####"
                    displayType={'text'}
                />
                : 
                <NumberFormat
                value={props.number}
                type="text"
                format="###-####-####"
                displayType={'text'}
            />}</>
    )
}
