import React, { createContext, useContext, useState, useEffect } from 'react';
import { usePrefixContext } from './PrefixContext';
import { console_log } from '../js/dev';
import api from './../js/api';
import { useLoadingContext } from './LoadingContext';

export const AuthContext = createContext();

function AuthContextProvider(props) {
    const [activeUser, setActiveUser] = useState(null);
    const [isCompleted, setIsCompleted] = useState(false);
    const { isCompleted: prefixCtxIsCompleted } = usePrefixContext();
    const {presentLoading, dismissLoading} = useLoadingContext();
    const [refreshCountdown, setRefreshCountdown] = useState(0);
    const [isRefreshingToken, setIsRefreshingToken] = useState(false);
    const refreshTimeLimit = (3 * 60);

    useEffect(() => {
        if (prefixCtxIsCompleted) {
            init();
        }
    }, [prefixCtxIsCompleted]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (activeUser !== null && !isRefreshingToken) {
                setRefreshCountdown(refreshCountdown - 1);
                if (refreshCountdown <= 0) {
                    refresh();
                }
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [refreshCountdown, activeUser]);

    const init = async () => {
        const refreshToken = localStorage.getItem(refreshTokenStorageKey);
        if (refreshToken === null) {
            await setIsCompleted(true);
            return null;
        }
        await refresh();
    }

    const refresh = async () => {
        try {
            setIsRefreshingToken(true);
            const refreshToken = localStorage.getItem(refreshTokenStorageKey);
            const fresh = await api.post(`/refresh`, {}, { headers: { 'Authorization': `Bearer ${refreshToken}` } });
            saveAuthenticationToken(fresh.data.token, fresh.data.refresh_token, true);
        } catch (error) {
            setIsCompleted(true);
        } finally {
            await setRefreshCountdown(refreshTimeLimit);
            setIsRefreshingToken(false);
        }
    }

    const updateApiAuthentication = async () => {
        const token = localStorage.getItem(accessTokenStorageKey);
        if (token !== null) {
            console_log('add token to api header');
            api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
            if (activeUser === null) {
                try {
                    presentLoading();
                    await fetchUserData();                    
                } catch (error) {
                    console_log('remove token to api header');
                    logOut();
                } finally {
                    dismissLoading();
                }
            }
        } else {
            console_log('remove token to api header');
            delete api.defaults.headers.common["Authorization"];
        }
        if (!isCompleted) {
            setIsCompleted(true);
        }
    }

    const fetchUserData = async () => {
        try {
            const fetch = await  api.get(`profile`, {loading: true});
            await setActiveUser(fetch.data);
            return fetch.data;
        } catch (error) {
        }
    }

    const saveAuthenticationToken = (a, r, u) => {
        console_log(a, r, u)
        if (a) {
            localStorage.setItem(accessTokenStorageKey, a);
        }

        if (r) {
            localStorage.setItem(refreshTokenStorageKey, r);
        }

        if (u === true) {
            updateApiAuthentication();
        }
    }

    const logOut = async () => {
        setActiveUser(null);
        localStorage.removeItem(accessTokenStorageKey);
        localStorage.removeItem(refreshTokenStorageKey);
        delete api.defaults.headers.common["Authorization"];
    }

    const logIn = async (data) => {
        return new Promise((resolve, reject) => {
            api.post(`login`, data).then((res) => {
                saveAuthenticationToken(res.data.token, res.data.refresh_token, true);
                resolve(res.data);
            }).catch((err) => {
                reject(err);
            });
        });
    }

    const registerUser = async (data) => {
        return new Promise((resolve, reject) => {
            api.post(`register`, data).then((res) => {
                saveAuthenticationToken(res.data.token, res.data.refresh_token, true);
                resolve(res.data);
            }).catch((err) => {
                reject(err);
            })
        });
    }

    const value = { activeUser, isCompleted, updateApiAuthentication, saveAuthenticationToken, logOut, logIn, registerUser, fetchUserData };

    return (
        <AuthContext.Provider value={value}>
            {props.children}
        </AuthContext.Provider>
    )
}

function useAuthContext() {
    const context = useContext(AuthContext)
    if (context === undefined) {
        throw new Error('usePrefix must be used within a AuthProvider')
    }
    return context
}

const accessTokenStorageKey = "a-token";
const refreshTokenStorageKey = "r-auth";

export { useAuthContext, accessTokenStorageKey, refreshTokenStorageKey }

export default AuthContextProvider;