import React from 'react';
import { useTranslation } from 'react-i18next';
import ContactInfoRow from './ContactInfoRow';
export default function FullContactInfo(props) {
    const { t } = useTranslation();
    const { webContact } = props;

    return (
        <div>
            {
                webContact.phone !== null && 
                <div className="mb-4">
                    <h6 className="text-start fw-bold">{t('phone_number')}</h6>
                    {webContact.phone.split(",").map((item) => {
                        return <ContactInfoRow url={item}  key={`contact-info-row-phone-${item}`} type="phone" />
                    })}
                </div>
            }
            {
                webContact.email !== null && 
                <div className="mb-4">
                    <h6 className="text-start fw-bold">{t('email')}</h6>
                    <ContactInfoRow url={webContact.email} type="email" />
                </div>
            }
            {
                webContact.whatsapp !== null && 
                <div className="mb-4">
                    <h6 className="text-start fw-bold">{t('whatsapp')}</h6>
                    <ContactInfoRow url={webContact.whatsapp} name={webContact.whatsapp_name} image={webContact.whatsapp_img} type="whatsapp" />
                </div>
            }
            {
                webContact.fb !== null && 
                <div className="mb-4">
                    <h6 className="text-start fw-bold">{t('fb')}</h6>
                    <ContactInfoRow url={webContact.fb} name={webContact.fb_name} image={webContact.fb_img} type="fb" />
                </div>
            }
            {
                webContact.line !== null && 
                <div className="mb-4">
                    <h6 className="text-start fw-bold">{t('line_app')}</h6>
                    <ContactInfoRow url={webContact.line} name={webContact.line_name} image={webContact.line_img} type="line" />
                </div>
            }
        </div>
    )
}
