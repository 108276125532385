import React from 'react';
import { Controller } from 'react-hook-form';
import FormControlTypeToggle from './FormControlTypeToggle';
import './../../css/form.css';

export default function HookFormItems(props) {

    function RequiredInputMark({ required }) {
        return (
            required ? <span className="text-danger"> *</span> : null
        )
    }

    function InputErrorMessage({ error, className }) {
        return <>{error ? <div className={`form-text text-danger ${className && className.errorMessage ? className.errorMessage : ''}`}>{error.message}</div> : null}</>
    }

    function InputHelp({ help }) {
        return <>{help ? <div className={`form-text text-black-50 small`}>{help}</div> : null}</>
    }

    function InputContainer({ attr, className, children }) {
        return (
            <div className={`col-12 mb-3 text-start ${attr.hidden === true || attr.type === "hidden" ? "d-none" : ""} ${className && className.controlGroup ? className.controlGroup : ""} `}>
                {children}
            </div>
        )
    }

    function Label({ label, attr, requiredOptions }) {
        return (
            <label htmlFor={attr.id} className="form-label">{label}{RequiredInputMark(requiredOptions)}</label>
        )
    }

    return (
        <>
            {props.formFields.map((field, index) => {
                const { label, requiredOptions, attr, selectOptions, controlTypeToggle, callback, className, defaultValue, help } = field;
                return (
                    <Controller
                        key={`form_field_${attr.id}`}
                        name={attr.name}
                        control={props.control}
                        defaultValue={defaultValue}
                        render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState,
                        }) => (
                            <>
                                {
                                    {
                                        "select":
                                        <div className={`col-12 mb-3 text-start ${attr.hidden === true || attr.type === "hidden" ? "d-none" : ""} ${className && className.controlGroup ? className.controlGroup : ""} `}>
                                                <Label label={label} attr={attr} requiredOptions={requiredOptions} />
                                                <div className="form-control-group-input">
                                                    <select className="form-select" {...attr} {...props.register(attr.name, requiredOptions ? requiredOptions : {})}
                                                        onChange={(e) => {
                                                            onChange(e);
                                                            if (typeof callback !== "undefined" && typeof callback.onChange !== "undefined") {
                                                                callback.onChange(e)
                                                            }
                                                        }}
                                                        onBlur={(e) => {
                                                            onBlur(e);
                                                            if (typeof callback !== "undefined" && typeof callback.onBlur !== "undefined") {
                                                                callback.onBlur(e)
                                                            }
                                                        }}
                                                    >
                                                        {
                                                            selectOptions && selectOptions.map((opt, optId) => {
                                                                return (
                                                                    <option value={opt.value} key={`opt-${attr.name}-${optId}-${opt.value}`}>{opt.text}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                                <InputHelp help={help} />
                                                <InputErrorMessage error={props.errors[attr.name]} className={className} />
                                            </div>
                                        , "textarea":
                                        <div className={`col-12 mb-3 text-start ${attr.hidden === true || attr.type === "hidden" ? "d-none" : ""} ${className && className.controlGroup ? className.controlGroup : ""} `}>
                                                <Label label={label} attr={attr} requiredOptions={requiredOptions} />
                                                <div className="form-control-group-input">
                                                    <textarea className="form-control" {...attr} {...props.register(attr.name, requiredOptions ? requiredOptions : {})}
                                                        onChange={(e) => {
                                                            onChange(e);
                                                            if (typeof callback !== "undefined" && typeof callback.onChange !== "undefined") {
                                                                callback.onChange(e)
                                                            }
                                                        }}
                                                        onBlur={(e) => {
                                                            onBlur(e);
                                                            if (typeof callback !== "undefined" && typeof callback.onBlur !== "undefined") {
                                                                callback.onBlur(e)
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <InputHelp help={help} />
                                                <InputErrorMessage error={props.errors[attr.name]} className={className} />
                                            </div>
                                    }[attr.type] ||
                                    <div className={`col-12 mb-3 text-start ${attr.hidden === true || attr.type === "hidden" ? "d-none" : ""} ${className && className.controlGroup ? className.controlGroup : ""} `}>
                                        <Label label={label} attr={attr} requiredOptions={requiredOptions} />
                                        <div className="form-control-group-input">
                                            {controlTypeToggle ?
                                                <FormControlTypeToggle a={controlTypeToggle.a} b={controlTypeToggle.b} refState={controlTypeToggle.refState} setRefState={controlTypeToggle.setRefState} aIcon={controlTypeToggle.aIcon} bIcon={controlTypeToggle.bIcon} /> : null}
                                            <input className="form-control" {...attr} {...props.register(attr.name, requiredOptions ? requiredOptions : {})}
                                                onChange={(e) => {
                                                    onChange(e);
                                                    if (typeof callback !== "undefined" && typeof callback.onChange !== "undefined") {
                                                        callback.onChange(e)
                                                    }
                                                }}
                                                onBlur={(e) => {
                                                    onBlur(e);
                                                    if (typeof callback !== "undefined" && typeof callback.onBlur !== "undefined") {
                                                        callback.onBlur(e)
                                                    }
                                                }}
                                            />
                                        </div>
                                        <InputHelp help={help} />
                                        <InputErrorMessage error={props.errors[attr.name]} className={className} />
                                    </div>
                                }
                            </>
                        )}
                    />
                );
            })}
        </>
    )
}
