import React from 'react';
import PageWrapper from './PageWrapper';
import { useAuthContext } from '../../context/AuthContext';
import { useTranslation } from 'react-i18next';
import PsButton from '../element/PsButton';
import { NavLink } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { FaSignOutAlt, FaRegUser } from 'react-icons/fa';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import PageProfileChangePassword from './PageProfileChangePassword';
import PageProfileInfo from './PageProfileInfo';
// import PageProfilePhoneNumberForSale from './PageProfilePhoneNumberForSale';
import PageProfileMyStore from './PageProfileMyStore';
import { BsSim } from 'react-icons/bs';
import { FiUnlock, FiLogOut } from 'react-icons/fi';
import './../../css/table.css';

export default function PageProfile() {
  const authContext = useAuthContext();
  const { t } = useTranslation();
  const { page } = useParams();
  const MySwal = withReactContent(Swal);

  const logOut = () => {
    MySwal.fire({
      title: <p>{t('logout')}</p>,
      html: (<p>{t('logout_confirm')}</p>),
      icon: 'question',
      showCloseButton: true,
      confirmButtonText: (<><FaSignOutAlt /> {t('logout')}?</>),
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-danger',
      }
    }).then((result) => {
      if (result.isConfirmed) {
        authContext.logOut();
      }
    });
  }

  const navActiveClasses = "active nav-link text-md-start py-md-3";
  const navInactiveClasses = "nav-link text-md-start py-md-3";
  return (
    <PageWrapper>
      <div className="container-lg">

        <div className="row">
          <div className="col-md-3">
            <nav className="nav my-account-nav nav-pills nav-fill flex-md-column my-3 mb-md-0">
              <NavLink to={{ pathname: '/account' }} className={((navData) => (navData.isActive ? navActiveClasses : navInactiveClasses))} end><FaRegUser /> {t('my_profile_info')}</NavLink>
              <NavLink to={{ pathname: '/account/my-store/phone-number' }} className={((navData) => (navData.isActive ? navActiveClasses : navInactiveClasses))}><BsSim /> {t('phone_number_for_sale')}</NavLink>
              <NavLink to={{ pathname: '/account/change-password' }} className={((navData) => (navData.isActive ? navActiveClasses : navInactiveClasses))}><FiUnlock /> {t('change_password')}</NavLink>
              <a href="#" className={`text-danger ${navInactiveClasses}`} onClick={logOut}><FiLogOut /> {t('logout')}</a>
            </nav>
          </div>
          <div className="col-md-9">
            <div className="rounded-white shadow-sm bg-white px-3 py-4">
            {
              {
                "change-password": <PageProfileChangePassword />,
                // "phone-number": <PageProfilePhoneNumberForSale />,
                "my-store": <PageProfileMyStore />
              }[page] || <PageProfileInfo />
            }
             </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  )
}
