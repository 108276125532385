import React from 'react';
import DOMPurify from 'dompurify';

const HtmlContent = (props) => {

    const createMarkup = (dirty) => {
        const clean = DOMPurify.sanitize(dirty);
        return {__html: clean};
      }

  return <div dangerouslySetInnerHTML={createMarkup(props.html)} />;
}

export default HtmlContent