import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { BsChevronDoubleLeft, BsChevronDoubleRight } from  'react-icons/bs';
import { t } from 'i18next';

export default function PaginatedItems({ itemsPerPage, itemsLength, initPage, onPageChange }) {
    // We start with an empty list of items.
    // const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);

    useEffect(() => {
        // Fetch items from another resources.
        const endOffset = itemOffset + itemsPerPage;
        console.log(`Loading items from ${itemOffset} to ${endOffset}`);
        // setCurrentItems(items.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(itemsLength / itemsPerPage));
    }, [itemOffset, itemsPerPage]);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % itemsLength;
        if (onPageChange) {
            onPageChange(event.selected, newOffset)
        }
        // console.log(
        //     `User requested page number ${event.selected}, which is offset ${newOffset}`
        // );
        setItemOffset(newOffset);
    };

    return (
        <div className="pagination">
            <ReactPaginate
                breakLabel="..."
                nextLabel={<>{t('next')} <BsChevronDoubleRight /></>}
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel={<><BsChevronDoubleLeft /> {t('prev')}</>}
                renderOnZeroPageCount={null}
                initialPage={initPage}
            />
        </div>
    );
}
