import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageWrapper from './PageWrapper';
import api from '../../js/api';
import MainNav from '../nav/MainNav';
import HtmlContent from '../HtmlContent';
import { FcOnlineSupport } from 'react-icons/fc';
import SectionWrapper from '../SectionWrapper';

export default function PageFaq() {
    const { t } = useTranslation();
    const [faq, setFaq] = useState([]);

    useEffect(() => {
        getFaq();
    }, []);

    const getFaq = async () => {
        try {
            const fetch = await api.get(`faq`);
            setFaq(fetch.data);
        } catch (error) {
        }
    }

    return (
        <PageWrapper>
            <SectionWrapper>
                <MainNav pageTitle={t('faq')} pageTitleIcon={<FcOnlineSupport className='h1 d-inline mb-0' />}></MainNav>
                {faq.map((item) => {
                    return (
                        <div className="accordion mb-2" id={`accordion-faq-${item.id}`} key={`faq-item-${item.id}`}>
                            <div className="accordion-item rounded-0">
                                <h2 className="accordion-header" id={`faq-item-title-${item.id}`}>
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#faq-item-collapse-${item.id}`} aria-expanded="false" aria-controls={`faq-item-collapse-${item.id}`}>
                                        <h6 className="fw-bold m-0 text-dark">{item.question}</h6>
                                    </button>
                                </h2>
                                <div id={`faq-item-collapse-${item.id}`} className="accordion-collapse collapse" aria-labelledby={`faq-item-title-${item.id}`} >
                                    <div className="accordion-body">
                                        <div className="ps-3">
                                            <HtmlContent html={item.answer} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}

            </SectionWrapper>
        </PageWrapper>
    )
}
