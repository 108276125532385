import React from 'react';
import { useParams, NavLink } from 'react-router-dom';
import MyStorePhoneNumber from './my-store-phone-number/MyStorePhoneNumber';

export default function PageProfileMyStore() {
    const { nestedPage } = useParams();

  return (
    <MyStorePhoneNumber />
  )
}
