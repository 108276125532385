const console_log = (message) => {
    if (process.env.NODE_ENV === "development") {
        console.log(message);
    }
}

const console_success = (message) => {
    if (process.env.NODE_ENV === "development") {
        console.log(`%c ${message} `, 'color: white; background: green');
    }
}

const console_danger = (message) => {
    if (process.env.NODE_ENV === "development") {
        console.log(`%c ${message} `, 'color: white; background: red');
    }
}

const console_warning = (message) => {
    if (process.env.NODE_ENV === "development") {
        console.log(`%c ${message} `, 'color: black; background: orange');
    }
}

const console_info = (message) => {
    if (process.env.NODE_ENV === "development") {
        console.log(`%c ${message} `, 'color: white; background: blue');
    }
}


function sleep(time) {
    return new Promise((resolve) => setTimeout(resolve, time * 1000));
}

export {
    sleep,
    console_log,
    console_success,
    console_danger,
    console_warning,
    console_info,
}