import axios from 'axios';
import { fallbackLng } from '../i18nextConf';
// import { useLoadingContext } from '../context/LoadingContext';
// import {presentLoading}

// const { presentLoading, dismissLoading } = useLoadingContext();
// const loadingContext = useLoadingContext();

const api = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  headers: { 'Content-Type': "application/json" }
});

const setBaseUrl = () => {
  let lang = localStorage.getItem("lang");
  if(lang === null){
    lang = fallbackLng;
  }
  let apiHost = process.env.REACT_APP_API_HOST.replace(/{lang}/g, lang);
  api.defaults.baseURL = apiHost;
  console.log(apiHost)
}
setBaseUrl();

// api.interceptors.request.use(function () {
//   presentLoading()
//   // Do something before request is sent
//   // return true;
// }, function (error) {
//   // Do something with request error
//   return Promise.reject(error);
// });

export { setBaseUrl }
export default api