import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import './index.css';
import './i18nextConf';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AuthContextProvider from './context/AuthContext'
import LoadingContextProvider from './context/LoadingContext';
import PrefixContextProvider from './context/PrefixContext';
import LayoutContextProvider from './context/LayoutContext';

const container = document.getElementById('root');
const root = ReactDOMClient.createRoot(container);
root.render(
  <React.StrictMode>
    <LoadingContextProvider>
      <PrefixContextProvider>
        <AuthContextProvider>
          <LayoutContextProvider>
            <App />
          </LayoutContextProvider>
        </AuthContextProvider>
      </PrefixContextProvider>
    </LoadingContextProvider>
  </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
