import React from 'react';
import PageWrapper from './PageWrapper';
import MainNav from '../nav/MainNav';
import { useTranslation } from 'react-i18next';
import SectionWrapper from '../SectionWrapper';
import FullContactInfo from './contact/FullContactInfo';
import { usePrefixContext } from '../../context/PrefixContext';

export default function PageContact() {
    const { t } = useTranslation();
    const { webContact, isCompleted } = usePrefixContext();
    return (
        <PageWrapper>
            <SectionWrapper>
                <MainNav pageTitle={t('contact_us')}></MainNav>
                {isCompleted  &&
                <FullContactInfo webContact={webContact} />
                }
            </SectionWrapper>
        </PageWrapper>

    )
}
