import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FiLogIn, FiEye, FiEyeOff } from 'react-icons/fi';
import HookFormItems from './HookFormItems';
import PsButton from '../element/PsButton';
import { useAuthContext } from '../../context/AuthContext';
import alert from '../../js/alert';

const FormLogin = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const authContext = useAuthContext();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { register, control, handleSubmit, formState: { errors, isDirty, isValid }, } = useForm({ reValidateMode: 'onChange', });
    const [passwordFieldType, setPasswordFieldType] = useState("");

    useImperativeHandle(ref, () => ({
        handleSubmit: handleSubmit(onSubmit, onError),
        errors: errors,
        isDirty: isDirty,
        isValid: isValid,
        isSubmitting: isSubmitting,
    }));

    const [errorInfo, setErrorInfo] = useState({});

    const loginFields = [
        {
            label: t('email'),
            requiredOptions: {
                required: t('please_enter_field_value'),
            },
            attr: {
                name: "username",
                type: "text",
                inputMode: "text",
                placeholder: t('email'),
                id: "form-login-username-field",
                autoComplete: "off",
            }
        },
        {
            label: t('password'),
            requiredOptions: {
                required: t('please_enter_field_value'),
            },
            attr: {
                name: "password",
                type: passwordFieldType,
                inputMode: undefined,
                placeholder: t('password'),
                id: "form-login-password-field",
                autoComplete: "off",
            },
            controlTypeToggle: {
                a: "password",
                b: "text",
                refState: passwordFieldType,
                setRefState: setPasswordFieldType,
                aIcon: <FiEye />,
                bIcon: <FiEyeOff />
            }
        }
    ];

    const onSubmit = async (data) => {
        try {
            setErrorInfo({ });
            setIsSubmitting(true);
            const login = await authContext.logIn(data);
            if (props.onSuccess) {
                props.onSuccess(login);
            }
        } catch (error) {
            onError(error);
        } finally {
            setIsSubmitting(false);
        }
    }

    const onError = (err) => {
        const errMessage = alert.tryGetErrorMessage(err);
        if (props.onError) {
            props.onError({ message: errMessage });
        }
        setErrorInfo({ message: errMessage });
        throw new Error(errMessage);
    }

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit, onError)} id="form-login" autoComplete="off">
                <fieldset disabled={isSubmitting}>
                    <div className="row">
                        <HookFormItems formFields={loginFields} register={register} control={control} errors={errors} isDirty={isDirty} isValid={isValid} />

                        <div className="col-12">
                            <PsButton type="submit" display="block" icon={<FiLogIn />} isLoading={isSubmitting}>{t('login')}</PsButton>
                        </div>

                        {(props.showErrorInfo === true && errorInfo.message) &&
                            <div className="col-12">
                                <div className="alert alert-danger mt-3" role="alert">
                                    {errorInfo.message}
                                </div>
                            </div>
                        }
                    </div>
                </fieldset>

            </form>
        </div>
    )
})

export default FormLogin