import React, { useEffect, useState } from 'react';
import PageWrapper from './PageWrapper';
import MainNav from '../nav/MainNav';
import { useTranslation } from 'react-i18next';
import SectionWrapper from '../SectionWrapper';
import api from '../../js/api';
import HtmlContent from '../HtmlContent';

export default function PageHowToAddPhoneNumberExcel() {
    const { t } = useTranslation();
    const [htmlContent, setHtmlContent] = useState(null);

    useEffect(() => {
        fetchData();
    }, []);
    
    const fetchData = async () => {
        try {
            const fetch = await api.get(`how-to-add-phone-number/excel`);
            setHtmlContent(fetch.data)
        } catch (error) {
            
        }
        }

    return (
        <PageWrapper>
            <SectionWrapper>
                <MainNav pageTitle={t('how_to_add_phone_number_with_excel')}></MainNav>
                {htmlContent !== null && <HtmlContent html={htmlContent} />}
            </SectionWrapper>
        </PageWrapper>
    )
}
