import React from 'react';
import { useTranslation } from 'react-i18next';
import { usePrefixContext } from '../context/PrefixContext';
import './../css/phone-number.css';
import PhoneNumberFormat from './element/PhoneNumberFormat';
import { currencyFormat } from '../js/utilities';
import { IoLogoWhatsapp } from 'react-icons/io';
import { BsTelephoneFill, BsLine, BsThreeDots } from 'react-icons/bs';
import PsButton from './element/PsButton';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import FullContactInfo from './page/contact/FullContactInfo';

export default function PhoneNumberItem(props) {
    const { id, number, price, provider_id, number_sum, sales_store_name, sales_name, package_type, paid_type, is_recommended } = props;
    const { t } = useTranslation();
    const { prefix, webContact, isCompleted } = usePrefixContext();
    const mySwal = withReactContent(Swal);

    const viewFullContactInfo = () => {
        mySwal.fire({
            title: <h3 className="fw-bold">{t('contact_info')}</h3>,
            html: <FullContactInfo webContact={webContact} />,
            confirmButtonText: <>{t('close')}</>,
            buttonsStyling: false,
            customClass: {
                confirmButton: 'btn btn-success',
                popup: 'swal-lg',
            },
        })
    }
    return (
        <>{isCompleted &&
            <div className={`phone-number-item shadow-sm ${is_recommended ? 'recommended' : ''}`} style={{ borderColor: prefix.sim_provider[provider_id].provider_color }}>

                <div className="d-flex justify-content-between align-items-center mb-2">
                    <div className="provider-image">
                        <img src={prefix.sim_provider[provider_id].provider_image} alt={prefix.sim_provider[provider_id].provider_name} />
                    </div>
                    <div className="number-box">
                        <div className="number">
                            <h5 style={{ color: prefix.sim_provider[provider_id].provider_color }} className="m-0"><PhoneNumberFormat number={number} /></h5>
                        </div>
                        <div className="text-end">
                            <span className="badge bg-tertiary">{prefix.sim_paid_type[paid_type].name} / {prefix.sim_package_type[package_type].name}</span>

                        </div>
                    </div>
                </div>

                {/* <div className="d-flex justify-content-between">
                <div className="">
                    {prefix.sim_paid_type[paid_type].name}
                </div>
                <div>
                    {prefix.sim_package_type[package_type].name}
                </div>
            </div> */}

                <div className="d-flex justify-content-between">
                    <div className="">
                        {t('phone_number_sum')}: <span className="text-success fw-bold">{number_sum}</span>
                    </div>
                    <div>
                        {t('price')}: <span className="text-tertiary fw-bold">{currencyFormat(price,0)}</span><small className="text-black-50">{prefix.currency_symbol}</small>
                    </div>
                </div>
                <hr className="my-2" />

                <div className="d-flex justify-content-between align-items-center flex-wrap">
                    <h6 className="fw-bold m-0"><i>{t('buy_now')}</i></h6>
                    <div>
                        {
                            webContact.phone !== null && <>
                                <a href={`tel:${webContact.phone.split(",")[0]}`} title={`${webContact.phone.split(",")[0]}`} className="btn btn-light me-1 text-app-whatsapp"><BsTelephoneFill /></a>
                            </>
                        }
                        {
                            webContact.whatsapp !== null && <>
                                <a href={`https://api.whatsapp.com/send?phone=${webContact.whatsapp}`} title={webContact.whatsapp_name !== null ? webContact.whatsapp_name : ""} className="btn btn-light me-1 text-app-whatsapp" ><IoLogoWhatsapp /></a>
                            </>
                        }
                        {
                            webContact.line !== null && <>
                                <a href={`${webContact.line}`} title={webContact.line_name !== null ? webContact.line_name : ""} className="btn btn-light me-1 text-app-whatsapp" ><BsLine /></a>
                            </>
                        }
                        {/* <PsButton color="transparent" title={t('full_contact_info')} icon={<BsThreeDots />} handleClick={() => viewFullContactInfo()}></PsButton> */}
                    </div>
                </div>
            </div>
        }</>
    )
}
